
import {handleActions} from 'redux-actions'

import {ADDED_TOGGLE, REMOVED_TOGGLE, ADDED_EXCLUSIVE_TOGGLE} from 'types'

export const initialState = []

const addToggle = (state, {payload}) => {
  return [...state, payload]
}

const removeToggle = (state, {payload}) => {
  return state.filter(id => id !== payload)
}

const addExclusiveToggle = (state, {payload}) => ([...state.filter(toggle => toggle.type !== payload.type), payload])

export const reducer = handleActions({
  [ADDED_TOGGLE]: addToggle,
  [REMOVED_TOGGLE]: removeToggle,
  [ADDED_EXCLUSIVE_TOGGLE]: addExclusiveToggle
}, initialState)
