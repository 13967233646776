import { call, fork, put, takeLatest } from 'redux-saga/effects'
import { AdminCrudSagas, next } from './shared'
import { adminFetchReviews } from 'actions'
import {
  ADMIN_FETCH_REVIEWS,
  ADMIN_CREATE_REVIEW,
  ADMIN_UPDATE_REVIEW,
  ADMIN_DELETE_REVIEW,
} from 'types'

class ReviewsSagas extends AdminCrudSagas {
  *adminFetch(action) {
    const { modelId: reviewId, requestId } = action.payload
    const url = `/admin/reviews/${reviewId}`
    try {
      const data = yield call([this.api, 'get'], url)

      const response = {
        collectionName: this.modelNames.plural,
        requestId,
        response: {
          data,
          meta: {},
        },
      }
      yield put(this.fetchSuccessAction(response))
      yield next(action)
    } catch (err) {
      console.error(err)
    }
  }

  *fetch(action) {
    const { modelId } = action.payload

    if (!!modelId) {
      yield this.adminFetch(action)
    } else {
      yield super.fetch(action)
    }
  }
}
const sagas = new ReviewsSagas({
  modelNames: { singular: 'review', plural: 'reviews' },
  endpoint: 'crud/reviews',
  fetchAction: adminFetchReviews,
  updateMethod: 'patch',
  preFetchActions: [],
})

export function* init() {
  yield fork(function* () {
    yield takeLatest(ADMIN_FETCH_REVIEWS, sagas.fetch.bind(sagas))
  })
  yield fork(function* () {
    yield takeLatest(ADMIN_CREATE_REVIEW, sagas.create.bind(sagas))
  })
  yield fork(function* () {
    yield takeLatest(ADMIN_UPDATE_REVIEW, sagas.update.bind(sagas))
  })
  yield fork(function* () {
    yield takeLatest(ADMIN_DELETE_REVIEW, sagas.delete.bind(sagas))
  })
}

export const routeTrigger = sagas.routeTrigger.bind(sagas)
