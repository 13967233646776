import {handleActions} from 'redux-actions'

import {
  ADMIN_PUSH_IS_BUSY,
  ADMIN_POP_IS_BUSY,
  ADMIN_CLEAR_IS_BUSY
} from 'types'

const initialState = {
  stack: 0,
  isBusy: false
}

export const reducer = handleActions({
  [ADMIN_PUSH_IS_BUSY]: (state, action) => {
    return {
      stack: state.stack + 1,
      isBusy: true
    }
  },
  [ADMIN_POP_IS_BUSY]: (state, action) => {
    let stack = Math.max(0, state.stack - 1)
    return {
      stack,
      isBusy: stack > 0
    }
  },
  [ADMIN_CLEAR_IS_BUSY]: (state, action) => {
    return {
      stack: 0,
      isBusy: false
    }
  },
}, initialState)
