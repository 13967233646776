import {combineReducers} from 'redux'
import {handleAction} from 'redux-actions'
import {reducer as breadcrumbs} from './breadcrumbs'
import {reducer as request} from './request'
import {reducer as settings} from './settings'
import {reducer as analytics} from './analytics'
import {reducer as errors} from './errors'
import {reducer as working} from './working'
import {reducer as me} from './me'
import {reducer as reservationsOptimized} from './reservationsOptimized'
import {
  ADMIN_FETCH_DOCS_SUCCEEDED,
  // ADMIN_API_CALL_FAILED
} from 'types'

const collectionsInitialState = {
  users: [],
  drivers: [],
  companies: [],
  vehicles: [],
  locations: [],
  pricingTiers: [],
  vehicleProps: [],
  reservations: [],
  markets: [],
  zipcodes: [],
  dGraphs: [],
  promotions: [],
}

const collectionReducer = collectionName => (state, action) => {
  return collectionName === action.payload.collectionName ? action.payload.response.data : state
}

let collections = {}
for (let name in collectionsInitialState) {
  collections[name] = handleAction(ADMIN_FETCH_DOCS_SUCCEEDED, collectionReducer(name), collectionsInitialState[name])
}

export const reducer = combineReducers({
  ...collections,

  me,
  settings,
  analytics,
  breadcrumbs,
  request,
  errors,
  working,
  reservationsOptimized
})
