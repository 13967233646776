/**
 * Small utility module to handle reading and writing values from/to Cookies
 */

import Cookies, { CookieSetOptions, CookieGetOptions } from 'universal-cookie'

const cookies = new Cookies()

const DEFAULT_COOKIES_DOMAIN = process.env.REACT_APP_COOP_DOMAIN

/**
 * Saves a value to a cookie
 * @param {string} cookieName The name of the cookie
 * @param {string|object} value The value to save, can be a POJO
 * @param {object} cookieOptions The options for cookie creation
 */
export const saveToCookie = (
  cookieName: string,
  value: unknown,
  cookieOptions: CookieSetOptions = {}
) => {
  cookies.set(cookieName, value, {
    domain: DEFAULT_COOKIES_DOMAIN,
    path: '/',
    ...cookieOptions,
  })
}

/**
 * Reads the value from the given cookie
 * @param {string} cookieName The name of the cookie to read
 * @param {object} cookieOptions The options for the cookie read operation
 */
export const getFromCookie = (
  cookieName: string,
  cookieOptions: CookieGetOptions = {}
) => cookies.get(cookieName, cookieOptions)

/**
 * Deletes the cookie with the given name
 * @param {string} cookieName The name of the cookie
 * @param {object} cookieOptions The options for the cookie
 */
export const deleteCookie = (
  cookieName: string,
  cookieOptions: CookieSetOptions = {}
) =>
  cookies.remove(cookieName, {
    domain: DEFAULT_COOKIES_DOMAIN,
    path: '/',
    ...cookieOptions,
  })
