import { fork, takeLatest, call, put } from 'redux-saga/effects'
import { AdminCrudSagas } from './shared'
import { AdminError } from 'state/reducers/admin/errors'
import {
  adminFetchLocations,
  adminPushIsBusy,
  adminPopIsBusy,
  adminApiCallFailed,
} from 'actions'

import {
  ADMIN_FETCH_LOCATIONS,
  ADMIN_CREATE_LOCATION,
  ADMIN_UPDATE_LOCATION,
  ADMIN_DELETE_LOCATION,
} from 'types'

class LocationsSagas extends AdminCrudSagas {
  *softDelete(action) {
    const { modelId: locationId } = action.payload
    try {
      yield put(adminPushIsBusy())
      yield call([this.api, 'post'], `/admin/locations/${locationId}/delete`)
      yield put(adminPopIsBusy())
    } catch (error) {
      yield put(adminPopIsBusy())
      yield put(
        adminApiCallFailed(
          new AdminError(`Delete ${this.modelNames.singular}`, error)
        )
      )
    }
  }
  *fetch(action) {
    yield super.fetch({
      ...action,
      populate: ['company'],
    })
  }
}
const sagas = new LocationsSagas({
  modelNames: { singular: 'location', plural: 'locations' },
  endpoint: 'crud/locations',
  fetchAction: adminFetchLocations,
  updateMethod: 'patch',
  apiVersion: 'v2',
})

export function* init() {
  yield fork(function* () {
    yield takeLatest(ADMIN_FETCH_LOCATIONS, sagas.fetch.bind(sagas))
  })
  yield fork(function* () {
    yield takeLatest(ADMIN_CREATE_LOCATION, sagas.create.bind(sagas))
  })
  yield fork(function* () {
    yield takeLatest(ADMIN_UPDATE_LOCATION, sagas.update.bind(sagas))
  })
  yield fork(function* () {
    yield takeLatest(ADMIN_DELETE_LOCATION, sagas.softDelete.bind(sagas))
  })
}

export const routeTrigger = sagas.routeTrigger.bind(sagas)
