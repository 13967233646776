import { saveToCookie, deleteCookie } from './cookies-storage'

const kCookieAuthenticationTokenKey = 'coop-admin-cookie'
const kCookieUserId = 'user-id'

interface PersistedAuthentication {
  loginToken: string
  loggedUser: {
    _id: string
    name: {
      first: string
      last: string
    }
    role: string
    profileImageUrl?: string
    username: string
    createdAt: string
    phone: string
  }
}

/**
 * Stores the given authentication data using the underlying cookie storage feature
 */
export const storeAuthentication = (auth: PersistedAuthentication): void => {
  const formattedAuth = JSON.stringify(auth)
  saveToCookie(kCookieAuthenticationTokenKey, formattedAuth)
  saveToCookie(kCookieUserId, auth.loggedUser._id)
}

/**
 * Deletes the authentication data from the underlying cookie storage feature
 */
export const clearAuthentication = (): void => {
  deleteCookie(kCookieAuthenticationTokenKey)
  deleteCookie(kCookieUserId)
}
