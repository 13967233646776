import {fork, takeLatest} from 'redux-saga/effects'
import {AdminCrudSagas} from './shared'
import {
  adminFetchPromotions,
  adminFetchMarkets
} from 'actions'
import {
  ADMIN_FETCH_PROMOTIONS,
  ADMIN_CREATE_PROMOTION,
  ADMIN_UPDATE_PROMOTION,
  ADMIN_DELETE_PROMOTION
} from 'types'

class PromotionsSagas extends AdminCrudSagas {

  // use the admin version
  * delete (action) {
    const { modelId } = action.payload
    const endpoint = `admin/promotions/${modelId}/delete`
    const method = 'post'
    yield super.delete(action, { method, endpoint })    
  }

}
const sagas = new PromotionsSagas({
  modelNames: { singular: 'promotion', plural: 'promotions' },
  endpoint: 'crud/promotions',
  fetchAction: adminFetchPromotions,
  preFetchActions: [adminFetchMarkets],
  updateMethod: 'patch',
  apiVersion: 'v2'
})

export function * init () {
  yield fork(function * () { yield takeLatest(ADMIN_FETCH_PROMOTIONS, sagas.fetch.bind(sagas)) })
  yield fork(function * () { yield takeLatest(ADMIN_CREATE_PROMOTION, sagas.create.bind(sagas)) })
  yield fork(function * () { yield takeLatest(ADMIN_UPDATE_PROMOTION, sagas.update.bind(sagas)) })
  yield fork(function * () { yield takeLatest(ADMIN_DELETE_PROMOTION, sagas.delete.bind(sagas)) })
}

export const routeTrigger = sagas.routeTrigger.bind(sagas)
