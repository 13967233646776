import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from 'styled-components'
import { MuiThemeProvider } from 'material-ui/styles'
import { MuiThemeProvider as CoreMuiThemeProvider } from '@material-ui/core/styles'
import { Messages } from 'view/Shared/Messages'
import { LoadingSpinnerApp } from 'view/Shared/LoadingSpinnerApp'
import JssProvider from 'react-jss/lib/JssProvider'
import { createGenerateClassName } from '@material-ui/core/styles'
import { materialTheme, scTheme, coreMaterialTheme } from 'view/theme'
import { StripeProvider } from 'react-stripe-elements'
import { CoopThemeProvider } from '@cpbtechnology/coop-shared/ui/themes/shared/context'
import { defaultTheme } from '@cpbtechnology/coop-shared/ui/themes'
import { PubNubClientProvider, NotificationsContainer } from 'Providers'
import { BaseUserProvider } from 'contexts/BaseUserContext'
import { connect } from 'react-redux'
// Need to swap out testing code via ENV file
import { breakpoints } from '../theme/breakpoints'

const Routes = React.lazy(() => import('view/Routes'))

const { REACT_APP_STRIPE_PK } = process.env

const ERRORS = {
  NO_STRIPE_API_KEY: new Error(
    `Stripe Configuration E rror: no Stripe API key provided.`
  ),
}

// avoid collisions when multiple versions of MUI may be in play.
// COOPFE-3563, COOPFE-3571, COOPFE-3530
const generateClassName = createGenerateClassName({
  seed: 'coop-admin',
  disableGlobal: true,
})

class App extends React.Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props)

    this.state = {
      stripe: null,
    }
  }
  componentDidMount() {
    if (!REACT_APP_STRIPE_PK) throw ERRORS.NO_STRIPE_API_KEY
    var initStripe = () =>
      this.setState({ stripe: window.Stripe(REACT_APP_STRIPE_PK) })

    if (window.Stripe) initStripe()
    else
      document.querySelector('#stripe-js').addEventListener('load', initStripe)
  }
  render() {
    // this.state.stripe will either be null or a Stripe instance
    // depending on whether Stripe.js has loaded.
    return (
      <CoreMuiThemeProvider theme={coreMaterialTheme}>
        <PubNubClientProvider>
          <MuiThemeProvider theme={materialTheme}>
            <CoopThemeProvider value={defaultTheme}>
              <ThemeProvider
                theme={{ ...scTheme, ...defaultTheme, breakpoints }}
              >
                <JssProvider generateClassName={generateClassName}>
                  <StripeProvider stripe={this.state.stripe}>
                    <Fragment>
                      <Helmet>
                        <title>COOP by Ryder</title>
                        <meta
                          name='description'
                          content='COOP is a platform that connects fleet managers that have idle vehicles to businesses that are looking to rent vehicles. COOP simplifies the process and paperwork required to safely share vehicles between business owners.'
                        />
                      </Helmet>
                      <Messages />
                      <NotificationsContainer />
                      <BaseUserProvider value={this.props.baseUser}>
                        <React.Suspense fallback={<LoadingSpinnerApp />}>
                          <Routes />
                        </React.Suspense>
                      </BaseUserProvider>
                    </Fragment>
                  </StripeProvider>
                </JssProvider>
              </ThemeProvider>
            </CoopThemeProvider>
          </MuiThemeProvider>
        </PubNubClientProvider>
      </CoreMuiThemeProvider>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    baseUser: state.admin.me,
  }
}

export default connect(mapStateToProps)(App)
