
import {call, takeLatest, put} from 'redux-saga/effects'
import {addMessage} from 'actions'
import api from 'state/utils/api'
import {
  SNACKBAR,
  CHARGE_RESERVATION,
  RUN_TRANSFERS_FOR_RESERVATION,
  AUTH_RESERVATION,
  SEND_RESERVATION_CONTRACT,
  SEND_DRIVER_RES_SMS
} from 'types'

function * chargeReservation ({payload}) {
  try {
    yield call([api, 'post'], `/stripe/charge-reservation/${payload._id}`)
  } catch (error) {
    console.log('error charging reservation', error)
  }
}

function * runTransfersForReservation ({payload}) {
  try {
    yield call([api, 'post'], `/stripe/run-transfers/${payload._id}`)
  } catch (error) {
    console.log('error running transfers', error)
  }
}

function * authReservation ({payload}) {
  try {
    yield call([api, 'post'], `/stripe/auth-reservation/${payload._id}`)
  } catch (error) {
    console.log('error authing reservation', error)
  }
}

function * sendReservationContract ({payload}) {
  try {
    const body = { contractType: payload.contractType }
    yield call([api, 'post'], `/admin/reservations/${payload.reservationId}/send-contract-email`, body)
  } catch (error) {
    yield put(addMessage('reservation-error', `Error sending contract: ${error.message}`, SNACKBAR))
  }
}

function * sendDriverResSms ({payload}) {
  try {
    yield call([api, 'post'], `/admin/reservations/${payload.reservationId}/send-driver-reservation-sms`)
  } catch (error) {
    yield put(addMessage('reservation-error', `Error sending driver SMS notification: ${error.message}`, SNACKBAR))
  }
}

export function * init () {
  yield takeLatest(CHARGE_RESERVATION, chargeReservation)
  yield takeLatest(AUTH_RESERVATION, authReservation)
  yield takeLatest(RUN_TRANSFERS_FOR_RESERVATION, runTransfersForReservation)
  yield takeLatest(SEND_RESERVATION_CONTRACT, sendReservationContract)
  yield takeLatest(SEND_DRIVER_RES_SMS, sendDriverResSms)
}
