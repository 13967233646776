import { request, generateMethods, versionedRequest, debounce } from './api-shared'

// TODO: for now, as we transition to v2 of the api,
// these requests will work for both v1 and v2. going forward,
// this module should really only work with v1.

// const v1Request = versionedRequest('1.0.0')
const v1Request = versionedRequest('>=1.0.0')

const methods = generateMethods(v1Request)
export const get = methods.get
export const put = methods.put
export const post = methods.post
export const patch = methods.patch
export const del = methods.del
export default { get, put, post, patch, del, request, debounce }
