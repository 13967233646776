import {fork, takeLatest, call, put} from 'redux-saga/effects'
import {NoTableAdminCrudSagas, next} from './shared'
import api from 'state/utils/api-v2'
import {AdminError} from 'state/reducers/admin/errors'
import {
  adminFetchSettings,
  adminFetchSettingsSucceeded,
  adminApiCallFailed
} from 'actions'
import {
  ADMIN_FETCH_SETTINGS,
  ADMIN_UPDATE_SETTINGS
} from 'types'

class SettingsSagas extends NoTableAdminCrudSagas {
  * update (action) {
    let settings = action.payload.model
    try {
      yield call([api, 'post'], `/admin/settings/`, { settings })
      yield next(action)
    } catch (error) {
      yield put(adminApiCallFailed(new AdminError(`Update Settings`, error)))
    }
  }
}
const sagas = new SettingsSagas({
  modelNames: { singular: 'setting', plural: 'settings' },
  endpoint: 'admin/settings',
  fetchAction: adminFetchSettings,
  fetchSuccessAction: adminFetchSettingsSucceeded,
  apiVersion: 'v2'
})

export function * init () {
  yield fork(function * () { yield takeLatest(ADMIN_FETCH_SETTINGS, sagas.fetch.bind(sagas)) })
  yield fork(function * () { yield takeLatest(ADMIN_UPDATE_SETTINGS, sagas.update.bind(sagas)) })
}

export const routeTrigger = sagas.routeTrigger.bind(sagas)
