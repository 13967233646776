
import {fork, takeLatest} from 'redux-saga/effects'
import {AdminCrudSagas} from './shared'
import {
  adminFetchCommEvents
} from 'actions'
import {
  ADMIN_FETCH_COMM_EVENTS
} from 'types'

class CommEventsSagas extends AdminCrudSagas { }
const sagas = new CommEventsSagas({
  modelNames: { singular: 'Comm Event', plural: 'Comm Events' },
  endpoint: 'comm-events',
  fetchAction: adminFetchCommEvents,
  apiVersion: 'v2'
})

export function * init () {
  yield fork(function * () { yield takeLatest(ADMIN_FETCH_COMM_EVENTS, sagas.fetch.bind(sagas)) })
}

export const routeTrigger = sagas.routeTrigger.bind(sagas)
