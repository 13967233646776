import {fork, takeLatest} from 'redux-saga/effects'
import {AdminCrudSagas} from './shared'
import {
  adminFetchMarkets,
  adminFetchPricingTiers,

} from 'actions'
import {
  ADMIN_FETCH_MARKETS,
  ADMIN_CREATE_MARKET,
  ADMIN_UPDATE_MARKET,
  ADMIN_DELETE_MARKET
} from 'types'

class MarketsSagas extends AdminCrudSagas { }
const sagas = new MarketsSagas({
  modelNames: { singular: 'market', plural: 'markets' },
  endpoint: 'crud/markets',
  fetchAction: adminFetchMarkets,
  preFetchActions: [adminFetchPricingTiers],
  updateMethod: 'patch',
  apiVersion: 'v2'
})

export function * init () {
  yield fork(function * () { yield takeLatest(ADMIN_FETCH_MARKETS, sagas.fetch.bind(sagas)) })
  yield fork(function * () { yield takeLatest(ADMIN_CREATE_MARKET, sagas.create.bind(sagas)) })
  yield fork(function * () { yield takeLatest(ADMIN_UPDATE_MARKET, sagas.update.bind(sagas)) })
  yield fork(function * () { yield takeLatest(ADMIN_DELETE_MARKET, sagas.delete.bind(sagas)) })
}

export const routeTrigger = sagas.routeTrigger.bind(sagas)
