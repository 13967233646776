import { fork, takeLatest } from 'redux-saga/effects'
import { AdminCrudSagas } from './shared'
import { adminFetchPricingTiers, adminFetchVehicleProps } from 'actions'
import {
  ADMIN_FETCH_PRICING_TIERS,
  ADMIN_CREATE_PRICING_TIER,
  ADMIN_UPDATE_PRICING_TIER,
  ADMIN_DELETE_PRICING_TIER,
} from 'types'

class PricingTiersSagas extends AdminCrudSagas {
  // use the admin version
  *update(action) {
    const { modelId } = action.payload
    const endpoint = `admin/pricing-tiers/${modelId}/update`
    const method = 'post'
    yield super.update(action, { method, endpoint })
  }

  // use the admin version
  *delete(action) {
    const { modelId } = action.payload
    const endpoint = `admin/pricing-tiers/${modelId}/delete`
    const method = 'post'
    yield super.delete(action, { method, endpoint })
  }
}

const options = {
  modelNames: { singular: 'pricingTier', plural: 'pricingTiers' },
  fetchAction: adminFetchPricingTiers,
  preFetchActions: [adminFetchVehicleProps],
  updateMethod: 'patch',
  apiVersion: 'v2',
  endpoint: 'crud/pricing-tiers',
}
const sagas = new PricingTiersSagas(options)

export function* init() {
  yield fork(function* () {
    yield takeLatest(ADMIN_FETCH_PRICING_TIERS, sagas.fetch.bind(sagas))
  })
  yield fork(function* () {
    yield takeLatest(ADMIN_CREATE_PRICING_TIER, sagas.create.bind(sagas))
  })
  yield fork(function* () {
    yield takeLatest(ADMIN_UPDATE_PRICING_TIER, sagas.update.bind(sagas))
  })
  yield fork(function* () {
    yield takeLatest(ADMIN_DELETE_PRICING_TIER, sagas.delete.bind(sagas))
  })
}

export const routeTrigger = sagas.routeTrigger.bind(sagas)
