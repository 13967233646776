import React, {Component} from 'react'
import {TweenMax, Linear} from 'gsap/TweenMax'
import {
  SpinnerWrapper
} from 'view/Shared/Structural'
import {LoadingSpinner} from 'view/Shared/LoadingSpinner'

export class LoadingSpinnerApp extends Component {
  componentDidMount () {
    TweenMax.to(['.left-circle', '.right-circle'], 1, {
      rotation: 360,
      transformOrigin: '50% 50%',
      repeat: -1,
      ease: Linear.easeNone
    })
  }

  render () {
    return (
      <SpinnerWrapper>
        <LoadingSpinner />
      </SpinnerWrapper>
    )
  }
}
