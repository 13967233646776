
import {fork, takeLatest} from 'redux-saga/effects'
import {AdminCrudSagas} from './shared'
import {
  adminFetchVehicleProps
} from 'actions'
import {
  ADMIN_FETCH_VEHICLE_PROPS,
  ADMIN_CREATE_VEHICLE_PROPS,
//   ADMIN_UPDATE_VEHICLE_PROPS,
//   ADMIN_DELETE_VEHICLE_PROPS
} from 'types'

class VehiclePropsSagas extends AdminCrudSagas { }
const sagas = new VehiclePropsSagas({
  modelNames: { singular: 'vehicleProps', plural: 'vehicleProps' },
  endpoint: 'crud/vehicle-props',
  fetchAction: adminFetchVehicleProps,
  updateMethod: 'patch',
  apiVersion: 'v2'
})

export function * init () {
  yield fork(function * () { yield takeLatest(ADMIN_FETCH_VEHICLE_PROPS, sagas.fetch.bind(sagas)) })
  yield fork(function * () { yield takeLatest(ADMIN_CREATE_VEHICLE_PROPS, sagas.create.bind(sagas)) })
//   yield fork(function * () { yield takeLatest(ADMIN_UPDATE_VEHICLE_PROPS, sagas.update.bind(sagas)) })
//   yield fork(function * () { yield takeLatest(ADMIN_DELETE_VEHICLE_PROPS, sagas.delete.bind(sagas)) })
}

export const routeTrigger = sagas.routeTrigger.bind(sagas)
