import React from 'react'
import styled from 'styled-components'
import { CoopThemeProvider } from '@cpbtechnology/coop-shared/ui/themes/shared/context'
import { useTheme } from 'hooks/useTheme'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { breakpoints } from './theme/breakpoints'
import { useTypeSet } from './theme/useTypeSet'
import PubNub from 'pubnub'
import { PubNubProvider } from 'pubnub-react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const ThemeProvider = ({ children }: { children: JSX.Element }) => {
  const typeSet = useTypeSet()
  const theme = useTheme()
  return (
    <StyledThemeProvider theme={{ ...theme, breakpoints, typeSet }}>
      <CoopThemeProvider value={{ ...theme, typeSet }}>
        {children}
      </CoopThemeProvider>
    </StyledThemeProvider>
  )
}

const pubnub = new PubNub({
  publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
  subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY!,
  uuid: 'NOT_SET',
})

export const PubNubClientProvider = ({ children }: { children: JSX.Element }) => {
  return (
    <PubNubProvider client={pubnub}>{children}</PubNubProvider>
  )
}

export const StyledContainer = styled.div`
  .Toastify__toast {
    background-color: ${({ theme }) => theme.palette.secondary.toHex()};
  }
`

export const StyledToastContainer = styled(ToastContainer).attrs({
  className: 'toast',
  toastClassName: 'toast',
  bodyClassName: 'body',
  progressClassName: 'progress',
})`

  .toast {
    .Toastify__close-button {
      color: white;
      opacity: 1;
      margin-top: 15px;
    }
  }

  #snack-container {
      background-color: #313131;
      top: 37px;

      .Toastify__progress-bar--wrp {
        // opacity instead of display none because it won't autohide
        opacity: 0;
      }
  }

  #toast-container {
    .progress {
      background: white;
    }
  }
`

export const NotificationsContainer = () => (
  <StyledContainer>
    {/* 
      We disable build-in icon and add our custom icons via
      `NotificationIcon` component
    */}
    <StyledToastContainer position='bottom-center' icon={false} />
  </StyledContainer>
)