import OktaAuth from '@okta/okta-auth-js'
import type { TokenResponse } from '@okta/okta-auth-js'
export type { TokenResponse } from '@okta/okta-auth-js'

const oktaDisabled = process.env.REACT_APP_OKTA_DISABLE === 'true'
/**
 * Tells if the OKTA integration is enabled or not.
 * @returns `true` if the integration is enabled, `false` otherwise.
 */
export const isOktaEnabled = () => !oktaDisabled

export const config = {
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID as string,
  issuer: process.env.REACT_APP_OKTA_ISSUER as string,
  redirectUri: `${process.env.REACT_APP_SITE_URL}/login/callback`,
  postLogoutRedirectUri: process.env.REACT_APP_SITE_URL,
  scopes: ['openid', 'profile', 'email'],
}

export const oktaClient = new OktaAuth(config)

/**
 * Get the ID token with the authorization code from URL
 */
export const getIdTokenFromUrl = async (): Promise<TokenResponse> => {
  const tokenResponse = await oktaClient.token.parseFromUrl()
  await oktaClient.tokenManager.setTokens(tokenResponse.tokens)
  oktaClient.tokenManager.clear()
  return tokenResponse
}

export const clearTokens = (): void => {
  oktaClient.tokenManager.clear()
}

export const oktaLogout = async () => {
  await oktaClient.signOut()
}
