import { UUserRole, EUserRole } from '@cpbtechnology/coop-shared/types/user'
import { ROLE } from 'types'
import _ from 'lodash'

/**
 *
 * Track events to Google Tag Manager
 *
 */
export const trackEvent = (data) => {
  if (window.dataLayer !== undefined) {
    window.dataLayer.push(data)
  }
}

/**
 *
 * @param {number} value The value which will be applied the $ sign to
 *
 * returns the value with the $ sign and the - sign in the correct place to avoid things like $-10.00
 */
export const currency = (value) =>
  `${value < 0 ? '-' : ''}$${Math.abs(value).toFixed(2)}`

/**
 *
 * @param {String} value The value which will be displayed as a percentage
 *
 * returns the value with the % sign
 */
export const percentage = (value) =>
  `${value < 0 ? '-' : ''}${Math.abs(value).toFixed(2)}%`

/**
 *
 * @param {statement} object an object of type statement full populate
 *
 *  returns an object with both the prepaidMilageAmt and the AdjustedMilesPerDay
 *  note: This function can be used only where the statement.rentalMaths.mathVersion value is 2020-03-12 or newer
 */
export const getAdjustedPrepaidMileage = (statement) => {
  const {
    prepaidMilesPerDayAdjustment,
    rentalMaths: { terms, prepaidMileageAmt, usage },
  } = statement
  let result = {
    prepaidMileageAmt: prepaidMileageAmt,
    prepaidMileagePerDay: terms.prepaidMileagePerDay,
    isAdjusted: false,
  }

  const hasAdjustedMiles =
    _.isNumber(prepaidMilesPerDayAdjustment) &&
    prepaidMilesPerDayAdjustment !== 0
  if (hasAdjustedMiles) {
    // Here we are just calculating the cost of the adjustment portion
    const adjustedSubtotal =
      terms.pricePerMile * prepaidMilesPerDayAdjustment * usage.daysOut
    const adjustment = adjustedSubtotal + terms.taxRate * adjustedSubtotal
    const adjustedPrepaidMileageAmt = prepaidMileageAmt + adjustment
    const adjustedMilesPerDay =
      terms.prepaidMileagePerDay + prepaidMilesPerDayAdjustment
    result = {
      prepaidMileageAmt: adjustedPrepaidMileageAmt,
      prepaidMileagePerDay: adjustedMilesPerDay,
      isAdjusted: true,
    }
  }
  return result
}

/**
 * Simpler function to capitalize a string
 * @param {String}
 */
export const capitalize = ([first, ...rest]) =>
  first.toUpperCase() + rest.join('').toLowerCase()

/**
 * Get the timezone of a reservation, timezone will be get from the vehicle location.
 * @param {Object} reservation
 *
 * @return {String} A timezone of the reservation, will default to America/New_York
 * in case of an error.
 */
export const getReservationTimezone = (reservation) => {
  let tz = 'America/New_York'
  try {
    tz = reservation.vehicle.location.timezone
  } catch (error) {
    // intentionally blank
  }
  return tz
}
export const isSuperAdmin = () => {
  return UUserRole.superAuth.includes(window.localStorage.getItem(ROLE))
}

export const isOnlySuperAdmin = () => {
  const isAdmin = EUserRole.super === window.localStorage.getItem(ROLE)
  return isAdmin
}

export const isOnlyBillingAdmin = () =>
  EUserRole.billing === window.localStorage.getItem(ROLE)

export const isUserRolAdmin = () => {
  return UUserRole.adminAuth.includes(window.localStorage.getItem(ROLE))
}

export const isSupportUserRole = () => {
  return [EUserRole.admin].includes(window.localStorage.getItem(ROLE))
}

export function trimDoubleSlash(path) {
  if (path.startsWith('//')) {
    return path.substr(1)
  } else {
    return path
  }
}
