import {handleActions} from 'redux-actions'

import {
  ADMIN_PUSH_BREADCRUMBS,
  ADMIN_POP_BREADCRUMBS,
  ADMIN_SET_BREADCRUMBS
} from 'types'

const initialState = []
const basePath = '/admin'

export class Breadcrumb {
  constructor (title, url, filters) {
    this.title = title
    this.url = (url && url.length) ? [basePath, url].join('/') : basePath
    this.filters = filters || []
  }
}

export const reducer = handleActions({
  [ADMIN_PUSH_BREADCRUMBS]: (state, action) => state.concat(action.payload),
  [ADMIN_POP_BREADCRUMBS]: (state, action) => state.slice(0, -action.payload),
  [ADMIN_SET_BREADCRUMBS]: (state, action) => action.payload
}, initialState)
