
import { TOKEN } from 'types'
import queryParams from 'query-params'
import _ from 'lodash'
import { store } from '../store'
import { setServerDbVersionSupport } from 'actions'
const { REACT_APP_API_URL } = process.env

export class ApiError extends Error {
  constructor (statusText, status, response) {
    super(statusText, status)
    // note response may be undefined if there was no json body
    this.response = response
    if (response.message) {
      this.message = response.message
      // restify will prefix some error messages with "ErrorType; caused by"
      const boilerplate = 'caused by '
      if (this.message.indexOf(boilerplate) !== -1) {
        const i = this.message.indexOf(boilerplate) + boilerplate.length
        this.message = this.message.substring(i)
      }
    }
  }
}

const updateServerState = (response) => {
  try {
    const serverIdString = response.headers.get('x-coop-server')
    if (serverIdString) {
      const serverId = JSON.parse(serverIdString)
      store.dispatch(setServerDbVersionSupport(serverId.db))
    }
  }
  catch (error) {
    // silently do nothing.
  }
}

export const handleResponse = async (response, responseFormat = 'json') => {
  if (response.ok) {
    let data
    if (response.status !== 204) {
      data = await response[responseFormat]()
    }
    updateServerState(response)
    return data
  } else {
    let responseData
    try {
      // if server returned some sensible error message, capture it.
      responseData = await response.json()
    } catch (e) {
      // otherwise, don't sweat it.
    }
    throw new ApiError(response.statusText, response.status, responseData)
  }
}

const adminCoopClient = {
  name: 'web-admin'
}

/**
* General request.
* @param {*} method
* @param {*} path
* @param {*} body
*/
export const request = async (method, path, body, responseFormat = 'json', extraHeaders = {}, stringifyBody = true) => {
  const token = window.localStorage.getItem(TOKEN)
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'X-COOP-Client': JSON.stringify(adminCoopClient),
    ...extraHeaders
  }

  // yuck. special case for allowing the browser to automatically set
  // content type. todo: a better way to do this.
  if (_.isUndefined(headers['Content-Type'])) {
    delete headers['Content-Type']
  }

 // token applies to all request except login
  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }

  const fetchOptions = {
    headers: headers,
    method: method,
    mode: 'cors'
   // credentials: 'include'
  }

  if (body) {
    fetchOptions.body = stringifyBody ? JSON.stringify(body) : body
  }

  const response = await window.fetch(`${REACT_APP_API_URL}${path}`, fetchOptions)

  return handleResponse(response, responseFormat)
}

/**
* General requestDownload.
*/
export const requestDownload = async (method, path, body, responseFormat = 'blob', extraHeaders = {}) => {
  return request(method, path, body, responseFormat, extraHeaders)
}

export const requestPostFile = async (path, body, responseFormat = 'json', extraHeaders = {}) => {
  const fileHeader = {
    'Content-Type': undefined
  }
  if (extraHeaders) {
    extraHeaders = { ...fileHeader, ...extraHeaders }
  }
  return request('post', path, body, responseFormat, extraHeaders, false)
}

/**
 * Capture a versioned copy of request()
 *
 * @param {String} version A semver version string to pass in the Accept-Version header.
 */
export const versionedRequest = (version) => {
  return async (method, path, body, responseFormat = 'json', extraHeaders = {}) => {
    let versionedHeaders = {
      'Accept-Version': version
    }
    if (extraHeaders) {
      extraHeaders = { ...versionedHeaders, ...extraHeaders }
    }
    return request(method, path, body, responseFormat, extraHeaders)
  }
}

export const debounce = (func, wait, immediate) => {
  let timeout
  return function () {
    const context = this
    const args = arguments
    const later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export const generateMethods = (requestFn) => {
  return {
    get: async (path, urlParams) => {
      const fetchParams = queryParams.encode(urlParams)
      return requestFn('GET', `${path}?${fetchParams}`)
    },
    put: async (path, putBody) => {
      return requestFn('PUT', path, putBody)
    },
    patch: async (path, patchBody) => {
      return requestFn('PATCH', path, patchBody)
    },
    post: async (path, postBody) => {
      return requestFn('POST', path, postBody)
    },
    del: async (path) => {
      return requestFn('DELETE', path)
    }
  }
}
