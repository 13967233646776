import React from 'react'
import { typeSet as typeSetDesktop } from '@cpbtechnology/coop-shared/ui/themes/shared/typeSet'
import { typeSet as typeSetMobile } from '@cpbtechnology/coop-shared/ui/themes/shared/typeSet/index.web.mobile'
import { breakpoints } from './breakpoints'

/**
 * Changes typeset dynamically based on current breakpoint
 */
export const useTypeSet = () => {
  const [typeSet, setTypeset] = React.useState(typeSetDesktop)
  React.useEffect(() => {
    const mediaQuery = window.matchMedia(`(max-width: ${breakpoints.md}px)`)
    setTypeset(mediaQuery.matches ? typeSetMobile : typeSetDesktop)
    const updateIsMobile = (event: MediaQueryListEvent) => {
      const isMobile = event.matches
      setTypeset(isMobile ? typeSetMobile : typeSetDesktop)
    }
    mediaQuery.addEventListener('change', updateIsMobile)
    return () => mediaQuery.removeEventListener('change', updateIsMobile)
  }, [])
  return typeSet
}
