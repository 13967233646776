import { PaginatorSsl } from '@cpbtechnology/coop-shared/types/action'
import { uniqueId } from 'lodash'
import { handleActions } from 'redux-actions'
import {
  ADMIN_FETCHING_RESERVATIONS_OPTIMIZED,
  ADMIN_FETCHING_RESERVATIONS_OPTIMIZED_RESET,
  ADMIN_FETCH_COMPANIES_NAMES_SUCCEEDED,
  ADMIN_FETCH_RESERVATIONS_OPTIMIZED_SUCCEEDED,
  ADMIN_SET_FILTERS,
  ADMIN_SET_PAGINATION,
  ADMIN_LOCALLY_DELETE_RESERVATION,
} from 'types'

// Paginator for global filters will be used to set a max size for response items.
// It will always request for the first page since table pagination will be client side.
const defaultPaginator = new PaginatorSsl({
  currentPage: 1,
  itemsPerPage: 4000,
}).toJSON()

const initialState = {
  companiesNames: [],
  reservations: [],
  reservationPaginator: {
    ...defaultPaginator,
    requestId: `initial-${uniqueId()}`,
  },
  filters: [],
  isFetchingReservations: false,
}

export const reducer = handleActions(
  {
    [ADMIN_FETCH_COMPANIES_NAMES_SUCCEEDED]: (state, action) => {
      return {
        ...state,
        companiesNames: action.payload,
      }
    },

    [ADMIN_FETCHING_RESERVATIONS_OPTIMIZED]: (state, action) => {
      return {
        ...state,
        isFetchingReservations: action.payload,
      }
    },

    [ADMIN_FETCH_RESERVATIONS_OPTIMIZED_SUCCEEDED]: (state, action) => {
      return {
        ...state,
        reservations: action.payload,
      }
    },

    [ADMIN_SET_PAGINATION]: (state, action) => {
      return {
        ...state,
        reservationPaginator: action.payload.pagination,
      }
    },

    [ADMIN_SET_FILTERS]: (state, action) => {
      return {
        ...state,
        filters: action.payload.filters,
      }
    },

    [ADMIN_FETCHING_RESERVATIONS_OPTIMIZED_RESET]: (state) => {
      return {
        ...initialState,
        companiesNames: state.companiesNames,
      }
    },
    [ADMIN_LOCALLY_DELETE_RESERVATION]: (state, { payload }) => {
      const reservations = state.reservations.filter(
        (reservation) => reservation._id !== payload._id
      )
      return {
        ...state,
        reservations,
      }
    },
  },
  initialState
)
