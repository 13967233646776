/**
 * Most sagas so far are associated with routes, and are
 * only available from within the route. (Actually that's not
 * quite true: they are available anytime *after* hitting a route,
 * making browser history an implicit part of app state.)
 *
 * This is arguably fine for side effects which are actually
 * route-specific but generally it's odd: actions are global,
 * but the things which respond to actions are not global. I can
 * import and dispatch an action from my component but I have no
 * a priori way of knowing whether anything will respond to the
 * action.
 *
 * (This coupling to routes is also happening for reducers/the
 * redux store, and seems equally problematic to me.)
 *
 * We will often want to be able to dispatch actions and execute
 * their side effects in multiple places. We definitely don't want
 * to have to make multiple actions/types/sagas/reducers JUST to
 * be able to do a single thing from multiple routes.
 *
 * So: calling this folder/module "global" to distinguish it from
 * the above pattern. If the app is loaded, these sagas will
 * respond to actions.
 *
 * There is also "base" which has a similar motivation but I'm
 * holding off from touching that for the moment...
 */
import {fork} from 'redux-saga/effects'
import {init as initReservations} from './reservations'

export function * init () {
  yield fork(initReservations)
}
