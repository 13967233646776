import { fork, takeLatest, call, put } from 'redux-saga/effects'
import { AdminCrudSagas, next } from './shared'
import {
  adminFetchCompanies,
  adminPushIsBusy,
  adminPopIsBusy,
  adminApiCallFailed,
} from 'actions'
import {
  ADMIN_CREATE_COMPANY,
  ADMIN_DELETE_COMPANY,
  ADMIN_FETCH_COMPANIES,
  ADMIN_UPDATE_COMPANY_DNR,
  ADMIN_UPDATE_COMPANY,
} from 'types'
import { AdminError } from 'state/reducers/admin/errors'

class CompaniesSagas extends AdminCrudSagas {
  *fetch(action) {
    yield super.fetch({
      ...action,
      populate: [
        'doNotRentOrList.addedBy.user',
        'premiumLenderCommission.updatedBy',
        'borrowerCOOPPhysicalDamage.updatedBy',
        'stripePayoutsDisabled.updatedBy.user',
      ],
    })
  }
  *create(action) {
    yield super.update(action, {
      method: 'post',
      endpoint: `admin/companies/create`,
    })
  }
  *update(action) {
    const { modelId } = action.payload
    yield super.update(action, {
      method: 'post',
      endpoint: `admin/companies/${modelId}/update`,
    })
  }
  *updateDNR(action) {
    const {
      payload: { id: companyId, ...data },
    } = action

    try {
      yield put(adminPushIsBusy())
      yield call(
        [this.api, 'post'],
        `/admin/companies/${companyId}/set-dont-rent-or-list`,
        data
      )

      next(action)
    } catch (error) {
      yield put(
        adminApiCallFailed(new AdminError(`Update ${companyId} DNR/DNL`, error))
      )
    }

    yield put(adminPopIsBusy())
  }
}
const sagas = new CompaniesSagas({
  modelNames: { singular: 'company', plural: 'companies' },
  endpoint: 'crud/companies',
  fetchAction: adminFetchCompanies,
  updateMethod: 'patch',
  apiVersion: 'v2',
})

export function* init() {
  yield fork(function* () {
    yield takeLatest(ADMIN_FETCH_COMPANIES, sagas.fetch.bind(sagas))
  })
  yield fork(function* () {
    yield takeLatest(ADMIN_CREATE_COMPANY, sagas.create.bind(sagas))
  })
  yield fork(function* () {
    yield takeLatest(ADMIN_UPDATE_COMPANY, sagas.update.bind(sagas))
  })
  yield fork(function* () {
    yield takeLatest(ADMIN_DELETE_COMPANY, sagas.delete.bind(sagas))
  })
  yield fork(function* () {
    yield takeLatest(ADMIN_UPDATE_COMPANY_DNR, sagas.updateDNR.bind(sagas))
  })
}

export const routeTrigger = sagas.routeTrigger.bind(sagas)
