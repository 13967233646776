
import {handleActions} from 'redux-actions'

import {
  HYDRATED_BASE,
  UPDATE_STRIPE_BORROWER,
  UPDATE_STRIPE_LENDER,
  LENDER_SET_DASHBOARD_LINK,
  UPDATE_LENDER_HAS_PENDING,
  UPDATE_BORROWER_HAS_PENDING,
  PROFILE_STATE_UPDATE_IMAGE,
  UPDATE_COOP_INSURANCE
} from 'types'

export const initialState = {
  companyId: '',
  companyName: '',
  companyCanBorrow: false,
  companyCanLend: false,
  companyAdminAllowBorrowing: true,
  lenderDashboardUrl: '',
  companyAddress: {
    city: '',
    state: '',
    street_1: '',
    street_2: '',
    zip: ''
  },
  name: {
    first: '',
    last: ''
  },
  userId: '',
  email: '',
  profileImageUrl: null,
  hasLiabilityInsurance: false,
  hasPhysicalDamageInsurance: false,
  borrower: {
    hasPending: false
  },
  lender: {
    hasPending: false
  },
  attentionFlags: {
    borrower: {
      lateReservations: 0,
      reservationsNeedingDrivers: 0
    },
    lender: {
      lateReservations: 0,
      reservationBookingsPending: 0,
      reservationsWithExtensionRequests: 0
    }
  }
}

const hydrateBase = (state, {payload}) => {
  const { company, user, attentionFlags } = payload
  // Add a total
  attentionFlags.total = (
    attentionFlags.borrower.lateReservations +
    attentionFlags.borrower.reservationsNeedingDrivers +
    attentionFlags.lender.lateReservations +
    attentionFlags.lender.reservationBookingsPending +
    attentionFlags.lender.reservationsWithExtensionRequests
  )

  return Object.assign({}, state, {
    companyId: company._id,
    companyName: company.name,
    companyAddress: company.billingAddress,
    companyCanBorrow: !!company.defaultPaymentSrc,
    companyCanLend: !!company.defaultPaymentDest,
    companyAdminAllowBorrowing: company.adminAllowBorrowing,
    companyHasVehicleReplacementCoverage: company.hasVehicleReplacementCoverage,
    name: user.name,
    userId: user._id,
    email: user.userName,
    profileImageUrl: user.profileImageUrl,
    hasLiabilityInsurance: company.hasLiabilityInsurance,
    hasPhysicalDamageInsurance: company.hasPhysicalDamageInsurance,
    attentionFlags
  })
}

const updateHasVehicleReplacementCoverage = (state, {payload}) => {
  return Object.assign({}, state, {
    companyHasVehicleReplacementCoverage: payload.hasVehicleReplacementCoverage
  })
}

const updateBorrowerAccount = (state, {payload}) => {
  return Object.assign({}, state, {
    companyCanBorrow: !!payload.defaultPaymentSrc
  })
}

const updateLenderAccount = (state, {payload}) => {
  return Object.assign({}, state, {
    companyCanLend: !!payload.defaultPaymentDest
  })
}

const setLenderDashboardLink = (state, {payload}) => {
  return Object.assign({}, state, {
    lenderDashboardUrl: payload.dashboardUrl
  })
}

const setBorrowerHasPendingReservations = (state, {payload}) => {
  const currentBorrowerState = state.lender
  currentBorrowerState.hasPending = payload.value

  return Object.assign({}, state, {
    borrower: currentBorrowerState
  })
}

const setLenderHasPendingReservations = (state, {payload}) => {
  const currentLenderState = state.lender
  currentLenderState.hasPending = payload.value

  return Object.assign({}, state, {
    lender: currentLenderState
  })
}

const setProfileImageUrl = (state, {payload}) => {
  return Object.assign({}, state, {
    profileImageUrl: payload.profileImageUrl
  })
}

export const reducer = handleActions({
  [HYDRATED_BASE]: hydrateBase,
  [UPDATE_STRIPE_BORROWER]: updateBorrowerAccount,
  [UPDATE_STRIPE_LENDER]: updateLenderAccount,
  [LENDER_SET_DASHBOARD_LINK]: setLenderDashboardLink,
  [UPDATE_LENDER_HAS_PENDING]: setLenderHasPendingReservations,
  [UPDATE_BORROWER_HAS_PENDING]: setBorrowerHasPendingReservations,
  [PROFILE_STATE_UPDATE_IMAGE]: setProfileImageUrl,
  [UPDATE_COOP_INSURANCE]: updateHasVehicleReplacementCoverage,
}, initialState)
