import { put, all } from 'redux-saga/effects'
import { Breadcrumb } from 'state/reducers/admin/breadcrumbs'

import {
  adminFetchReservations,
  adminFetchVehicles,
  adminFetchUsers,
  adminSetBreadcrumbs
} from 'actions'

export function * routeTrigger () {
  yield all([
    put(adminSetBreadcrumbs([new Breadcrumb('Dashboard')])),
    put(adminFetchReservations()),
    put(adminFetchVehicles()),
    put(adminFetchUsers())
  ])
}

export function * init () {

}
