import {/* call, */ fork} from 'redux-saga/effects'

import {routes} from 'state/sagas/routes'
import {watchForLogout} from 'state/sagas/authentication'
// import {init as initBase, watchForLogin} from 'state/sagas/base'
import { watchForSubmitRegisterForm } from 'state/sagas/login'
import {init as initGlobal} from 'state/sagas/global'
// import {handleSubmitBooking as initHandleSubmitBooking} from 'state/sagas/booking'

export function * sagas () {
  // yield call(initBase)
  // yield fork(watchForLogin)
  yield fork(initGlobal)
  yield fork(routes)
  yield fork(watchForLogout)
  yield fork(watchForSubmitRegisterForm)
}
