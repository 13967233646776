
import {handleActions} from 'redux-actions'

import {ADDED_MESSAGE, REMOVED_MESSAGE} from 'types'

export const initialState = []

const addMessage = (state, {payload}) => ([...state, {
  id: payload.id,
  text: payload.text,
  type: payload.type,
  linkDescription: payload.linkDescription,
  linkUrl: payload.linkUrl
}])
const removeMessage = (state, {payload}) => state.filter(m => m.id !== payload)

export const reducer = handleActions({
  [ADDED_MESSAGE]: addMessage,
  [REMOVED_MESSAGE]: removeMessage
}, initialState)
