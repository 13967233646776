import { select, takeLatest } from 'redux-saga/effects'
import { NOT_FOUND } from 'redux-first-router'

import {
  ROUTE_LOGIN,
  ROUTE_ADMIN_DASHBOARD,
  ROUTE_ADMIN_USERS,
  ROUTE_ADMIN_VEHICLES,
  ROUTE_ADMIN_VEHICLES_BULK,
  ROUTE_ADMIN_COMPANIES,
  ROUTE_ADMIN_LOCATIONS,
  ROUTE_ADMIN_PRICING_TIERS,
  ROUTE_ADMIN_REVIEWS,
  ROUTE_ADMIN_DRIVERS,
  ROUTE_ADMIN_RESERVATIONS,
  ROUTE_ADMIN_RESERVATIONS_OPTIMIZED,
  ROUTE_ADMIN_SETTINGS,
  ROUTE_ADMIN_MARKETS,
  ROUTE_ADMIN_PROMOTIONS,
  ROUTE_ADMIN_ZIPCODES,
  ROUTE_ADMIN_DGRAPHS,
  ROUTE_ADMIN_ANALYTICS,
  ROUTE_ADMIN_FIND,
  ROUTE_ADMIN_COMM_EVENTS,
  ROUTE_ADMIN_MODEL_VALIDATIONS,
  ROUTE_ADMIN_PENDING_ACCOUNTS,
  ROUTE_ADMIN_PENDING_VEHICLES,
  ROUTE_ADMIN_CHATS,
  ROUTE_ADMIN_RESERVATION_STATS,
  ROUTE_ADMIN_COOP_API,
  ROUTE_ADMIN_CACHE_REPORT,
  ROUTE_ADMIN_MULTI_VEHICLE_REQUEST,
  ROUTE_ADMIN_BULK_VEHICLE_EDITS,
  ROUTE_ADMIN_BULK_RESERVATION_EDITS,
  ROUTE_LOGIN_CALLBACK,
} from 'types'

import { routeType } from 'selectors'

import CoopRouteSagas from './CoopRouteSagas'
import { adminRouteTriggers as adminRouteSagas } from 'state/sagas/admin'

import { LoginRouteSagas } from 'state/sagas/login'
import { everyoneAuthFilter } from './authentication'

// use to allow access to routes by anyone without authentication,
// when no other hooks or actions are required.
// (by default, in CoopRouteSagas, route authentication is owner-level).
class EveryoneAuthRouteSagas extends CoopRouteSagas {
  constructor() {
    super()
    this.authFilter = everyoneAuthFilter
  }
}

const loginRouteSagas = new LoginRouteSagas()

CoopRouteSagas.appRouteSagas = {
  [ROUTE_LOGIN]: loginRouteSagas,
  [ROUTE_LOGIN_CALLBACK]: loginRouteSagas,
  [NOT_FOUND]: new EveryoneAuthRouteSagas(),
  [ROUTE_ADMIN_DASHBOARD]: adminRouteSagas[ROUTE_ADMIN_DASHBOARD],
  [ROUTE_ADMIN_USERS]: adminRouteSagas[ROUTE_ADMIN_USERS],
  [ROUTE_ADMIN_VEHICLES]: adminRouteSagas[ROUTE_ADMIN_VEHICLES],
  [ROUTE_ADMIN_VEHICLES_BULK]: adminRouteSagas[ROUTE_ADMIN_VEHICLES_BULK],
  [ROUTE_ADMIN_COMPANIES]: adminRouteSagas[ROUTE_ADMIN_COMPANIES],
  [ROUTE_ADMIN_LOCATIONS]: adminRouteSagas[ROUTE_ADMIN_LOCATIONS],
  [ROUTE_ADMIN_PRICING_TIERS]: adminRouteSagas[ROUTE_ADMIN_PRICING_TIERS],
  [ROUTE_ADMIN_REVIEWS]: adminRouteSagas[ROUTE_ADMIN_REVIEWS],
  [ROUTE_ADMIN_DRIVERS]: adminRouteSagas[ROUTE_ADMIN_DRIVERS],
  [ROUTE_ADMIN_RESERVATIONS]: adminRouteSagas[ROUTE_ADMIN_RESERVATIONS],
  [ROUTE_ADMIN_RESERVATIONS_OPTIMIZED]:
    adminRouteSagas[ROUTE_ADMIN_RESERVATIONS_OPTIMIZED],
  [ROUTE_ADMIN_SETTINGS]: adminRouteSagas[ROUTE_ADMIN_SETTINGS],
  [ROUTE_ADMIN_MARKETS]: adminRouteSagas[ROUTE_ADMIN_MARKETS],
  [ROUTE_ADMIN_PROMOTIONS]: adminRouteSagas[ROUTE_ADMIN_PROMOTIONS],
  [ROUTE_ADMIN_ZIPCODES]: adminRouteSagas[ROUTE_ADMIN_ZIPCODES],
  [ROUTE_ADMIN_DGRAPHS]: adminRouteSagas[ROUTE_ADMIN_DGRAPHS],
  [ROUTE_ADMIN_ANALYTICS]: adminRouteSagas[ROUTE_ADMIN_ANALYTICS],
  [ROUTE_ADMIN_FIND]: adminRouteSagas[ROUTE_ADMIN_FIND],
  [ROUTE_ADMIN_COMM_EVENTS]: adminRouteSagas[ROUTE_ADMIN_COMM_EVENTS],
  [ROUTE_ADMIN_MODEL_VALIDATIONS]:
    adminRouteSagas[ROUTE_ADMIN_MODEL_VALIDATIONS],
  [ROUTE_ADMIN_PENDING_ACCOUNTS]: adminRouteSagas[ROUTE_ADMIN_PENDING_ACCOUNTS],
  [ROUTE_ADMIN_PENDING_VEHICLES]: adminRouteSagas[ROUTE_ADMIN_PENDING_VEHICLES],
  [ROUTE_ADMIN_CHATS]: adminRouteSagas[ROUTE_ADMIN_CHATS],
  [ROUTE_ADMIN_MULTI_VEHICLE_REQUEST]: adminRouteSagas[ROUTE_ADMIN_MULTI_VEHICLE_REQUEST],
  [ROUTE_ADMIN_BULK_VEHICLE_EDITS]: adminRouteSagas[ROUTE_ADMIN_BULK_VEHICLE_EDITS],
  [ROUTE_ADMIN_RESERVATION_STATS]: adminRouteSagas[ROUTE_ADMIN_RESERVATION_STATS],
  [ROUTE_ADMIN_COOP_API]: adminRouteSagas[ROUTE_ADMIN_COOP_API],
  [ROUTE_ADMIN_CACHE_REPORT]: adminRouteSagas[ROUTE_ADMIN_CACHE_REPORT],
  [ROUTE_ADMIN_BULK_RESERVATION_EDITS]: adminRouteSagas[ROUTE_ADMIN_BULK_RESERVATION_EDITS],
}

// Watch for all actions dispatched that have an action type in our routesMap.
export function* routes() {
  const ROUTE_TYPES = Object.keys(CoopRouteSagas.appRouteSagas)
  yield takeLatest(ROUTE_TYPES, CoopRouteSagas.handleRouteChanged)
  // On initial load of the application we check our state for a route and run the necessary saga
  // if needed.
  const initialRouteType = yield select(routeType)
  yield CoopRouteSagas.handleRouteChanged({ type: initialRouteType })
}
