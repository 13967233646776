import {handleActions} from 'redux-actions'
import { ADMIN_FETCH_ANALYTICS_SUCCEEDED } from 'types'
import moment from 'moment'

const today = moment().format('YYYY-MM-DD')
const lastWeek = moment().subtract(7, 'days').format('YYYY-MM-DD')

const initialState = {
  begin: lastWeek,
  end: today,
  lenders: {
    total: 0,
    range: 0
  },
  rentalDays: {
    total: 0,
    range: 0
  },
  reservations: {
    total: 0,
    range: 0
  },
  users: {
    total: 0,
    range: 0
  },
  vehicles: {
    total: 0,
    range: 0
  }
}

export const reducer = handleActions({
  [ADMIN_FETCH_ANALYTICS_SUCCEEDED]: (state, action) => action.payload.response
}, initialState)
