
// Literally maps to the type used by the action for navigation.
export const routeType = state => state.location.type

// Return any given message that matches a type.
export const getMessage = (state, which) => state.messages.find(m => m.id === which)

export const mapCenter = state => state.find.map.center
export const searchRadius = state => state.find.map.searchRadius
export const mapZoom = state => state.find.map.zoom

export const findFleetAssetsOpen = state => state.find.shared.fleetAssetsOpen
export const findFleetLocations = state => state.find.shared.fleetLocationIDs.map(id => state.find.shared.fleetLocationsByID[id])
export const findFleetLocationAssets = state => state.find.shared.fleetLocationAssetIDs.map(id => state.find.shared.fleetLocationAssetsByID[id])
export const findFleetAssetsByID = state => state.find.shared.fleetLocationAssetsByID
export const findFleetLocationsByID = state => state.find.shared.fleetLocationsByID
export const findCurrentFleetLocation = state => state.find.shared.currentFleetLocation
export const findAssetCountsByLocationID = state => state.find.shared.assetCountByLocationId
export const findAssetCountsTotal = state => state.find.shared.totalAssetsCount

export const findFiltersOpen = state => state.find.filters.filtersOpen
export const findFilteredCount = state => state.find.filters.filteredCount
export const findFiltersAddress = state => state.find.filters.address
export const findFilterSelections = state => state.find.filterSelections
export const findDriverReservationState = state => state.driver
export const findLenderReservationState = state => state.lenderDropoff

export const accountEnlistAssetOpen = state => state.account.enlistAsset.open
export const accountActiveStep = state => state.account.enlistAsset.activeStep
export const accountSubmitting = state => state.account.enlistAsset.submitting
export const accountFleetAssetID = state => state.account.enlistAsset.fleetAssetID

export const baseCompanyId = state => state.base.companyId
export const baseCompanyName = state => state.base.companyName
export const baseAddress = state => state.base.companyAddress

// Check that a toggle id exists in our toggle state.
export const hasToggle = (state, id) => state.toggles.includes(id)

// Location
export const getCurrentlocation = state => state.location

export const loggedInUserId = state => state.base.userId

export const selectBaseUser = state => state.admin.me