import { down, up } from 'styled-breakpoints'

export const breakpoints = {
  xs: 375, // (Mob)
  sm: 480, // (Mob Plus)
  md: 768, // (Tab Portrait)
  lg: 992, // (Tab Landscape)
  xl: 1280, // (Dsk 13')
  xxl: 1440, // (Dsk 15')
}

export const deviceTypesMediaQueries = {
  upToSmallDevices: down('xs'),
  upToMediumDevices: down('sm'),
  upToLargeDevices: down('md'),
  largeDevices: up('lg'),
}

/**
 * The intent of this is to provide very specific media queries that
 * cannot be represented by the standard breakpoints above but are needed
 * to fine-tune certain elements under very specific screen sizes.
 */
export const customMediaQueries = {
  // Any device with a screen size up to this is considered a Mobile
  largeMobilesMediaQuery: `(max-width: 576px)`,
  // Screen size that is considered a Tablet device
  tabletsMediaQuery: `(min-width: 577px) and (max-width: 990px)`,
  // Anything same size or smaller than a Tablet
  upToTabletsMediaQuery: `(max-width: 990px)`,
  // Anything bigger than a Tablet
  desktopMediaQuery: `(min-width: 991px)`,
}
