import { handleActions } from 'redux-actions'
import {
  SET_SERVER_DB_VERSION_SUPPORT
} from 'types'

export const initialState = {
  dbVersionSupport: []
}

const setDbVersionSupport = (state, {payload}) => {
  return Object.assign({}, state, {
    dbVersionSupport: payload
  })
}

export const reducer = handleActions({
  [SET_SERVER_DB_VERSION_SUPPORT]: setDbVersionSupport
}, initialState)
