import { versionedRequest, generateMethods, request, debounce } from './api-shared'

const v2Request = versionedRequest('2.0.0')
const methods = generateMethods(v2Request)
export const get = methods.get
export const put = methods.put
export const post = methods.post
export const patch = methods.patch
export const del = methods.del
export default { get, put, post, patch, del, request, debounce }
