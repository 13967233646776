import { fork, takeLatest } from 'redux-saga/effects'
import { AdminCrudSagas } from './shared'
import { adminFetchReservations } from 'actions'
import {
  ADMIN_FETCH_RESERVATIONS,
  ADMIN_CREATE_RESERVATION,
  ADMIN_UPDATE_RESERVATION,
  ADMIN_DELETE_RESERVATION,
} from 'types'
import { Breadcrumb } from 'state/reducers/admin/breadcrumbs'
import { capitalize } from 'utils'

class ReservationsSagas extends AdminCrudSagas {
  getRouteBreadcrumbs(request) {
    const url = request.names.plural.split(' ').join('-').toLowerCase()
    if (request.isSingle) {
      const editWord = request.isNew ? 'New' : 'Edit'
      return [
        new Breadcrumb(capitalize(request.names.plural), 'reservations-opt/'),
        new Breadcrumb(
          `${editWord} ${capitalize(request.names.singular)}`,
          `${url}/${request.modelId}`
        ),
      ]
    } else {
      return [
        new Breadcrumb(capitalize(request.names.plural), 'reservations-opt/'),
      ]
    }
  }
}

const sagas = new ReservationsSagas({
  modelNames: { singular: 'Reservation', plural: 'reservations' },
  endpoint: 'crud/reservations',
  updateMethod: 'patch',
  fetchAction: adminFetchReservations,
  apiVersion: 'v2',
})

export function* init() {
  yield fork(function* () {
    yield takeLatest(ADMIN_FETCH_RESERVATIONS, sagas.fetch.bind(sagas))
  })
  yield fork(function* () {
    yield takeLatest(ADMIN_CREATE_RESERVATION, sagas.create.bind(sagas))
  })
  yield fork(function* () {
    yield takeLatest(ADMIN_UPDATE_RESERVATION, sagas.update.bind(sagas))
  })
  yield fork(function* () {
    yield takeLatest(ADMIN_DELETE_RESERVATION, sagas.delete.bind(sagas))
  })
}

export const routeTrigger = sagas.routeTrigger.bind(sagas)
