import { call, all, put, takeEvery, select } from 'redux-saga/effects'
import { storeAuthentication, clearAuthentication } from '../../utils/storer'
import { addMessage, routeLogin } from 'actions'
import {
  PERFORM_LOGOUT,
  TOKEN,
  PUBNUB_TOKEN,
  EXPIRES,
  USER_ID,
  ROLE,
  SNACKBAR,
  TERMS_CONDITIONS,
  ROUTE_LOGIN,
} from 'types'

import { post } from 'state/utils/api-v2'
import { setAuthToken } from 'state/utils/api-v3'
import { resetUserForAppcues } from 'utils/appcues'
import { oktaLogout, isOktaEnabled } from 'utils/okta'

// eslint-disable-next-line require-yield
export function* userIsInAdminGroup() {
  return adminGroupAuthFilter()
}

export function* attemptServerAuth({ username, password, rememberMe }) {
  return yield call(post, '/login', { userName: username, password })
}

export function* attemptServerAuthWithIdToken({ idToken }) {
  return yield call(post, '/login-with-idtoken', { idToken })
}

export function* handleServerAuthSucceeded(serverResponse) {
  const dataToCookie = {
    loggedUser: {
      _id: serverResponse.user._id,
      name: {
        first: serverResponse.user.name.first,
        last: serverResponse.user.name.last,
      },
      role: serverResponse.user.role,
      profileImageUrl: serverResponse.user.profileImageUrl,
      username: serverResponse.user.userName,
      createdAt: serverResponse.user.createdAt,
      phone: serverResponse.user.phone,
    },
    loginToken: serverResponse.token,
  }
  yield all([
    window.localStorage.setItem(TOKEN, serverResponse.token),
    window.localStorage.setItem(PUBNUB_TOKEN, serverResponse.pubnubToken),
    window.localStorage.setItem(EXPIRES, serverResponse.expires),
    window.localStorage.setItem(USER_ID, serverResponse.user._id),
    window.localStorage.setItem(ROLE, serverResponse.user.role),
    // We save auth on cookie because it's used by admin2.0 in iFrame.
    storeAuthentication(dataToCookie),
    setAuthToken(serverResponse.token),
  ])
}

// LOGOUT: A root level saga to watch for logout at all times.
function* handleLogout() {
  // @TODO Clear redux store.
  yield all([
    window.localStorage.removeItem(TOKEN),
    window.localStorage.removeItem(PUBNUB_TOKEN),
    window.localStorage.removeItem(EXPIRES),
    window.localStorage.removeItem(USER_ID),
    window.localStorage.removeItem(ROLE),
    resetUserForAppcues(),
    // We must remove auth from cookie to prevent accidental logins on Admin2.0.
    clearAuthentication(),
  ])

  if (isOktaEnabled()) {
    yield call(oktaLogout)
  } else {
    yield put(routeLogin())
  }
}

export function* handleAuthFailed(pathname) {
  const currentRouteName = yield select((state) => state.location.type)
  if (currentRouteName !== ROUTE_LOGIN) {
    const isLoggedIn = hasValidToken()
    const errorMessage = isLoggedIn
      ? "You don't have permissions to access the requested resource"
      : 'Please login to continue'
    yield put(addMessage('auth-error', errorMessage, SNACKBAR))
    if (isOktaEnabled() && hasTokenWithRole('owner')) {
      yield call(handleLogout)
    } else {
      yield put(routeLogin({ query: { redirect: pathname } }))
    }
  }
}

export function* watchForLogout() {
  yield takeEvery(PERFORM_LOGOUT, handleLogout)
}

const hasValidToken = () => {
  const hasToken = !!window.localStorage.getItem(TOKEN)
  const hasNonExpiredToken =
    window.localStorage.getItem(EXPIRES) > Math.floor(Date.now() / 1000)
  return hasToken && hasNonExpiredToken
}

const hasTokenWithRole = (roleToCheck) =>
  hasValidToken() && window.localStorage.getItem(ROLE) === roleToCheck

// AUTHENTICATION: Checks that an API token exists in localStorage and that the expiration time is
// greater than the current time at function request.
export const ownerAuthFilter = () =>
  !!window.localStorage.getItem(TERMS_CONDITIONS) && hasTokenWithRole('owner')

export const salesAuthFilter = () => hasTokenWithRole('sales')
export const adminAuthFilter = () => hasTokenWithRole('admin')
export const superAuthFilter = () =>
  billingAuthFilter() || hasTokenWithRole('super')
export const billingAuthFilter = () => hasTokenWithRole('billing')

export const adminOrSuperAuthFilter = () =>
  billingAuthFilter() || adminAuthFilter() || superAuthFilter()
export const adminGroupAuthFilter = () =>
  salesAuthFilter() ||
  adminAuthFilter() ||
  superAuthFilter() ||
  billingAuthFilter()
export const everyoneAuthFilter = () => true

// When authentication is successful we run the saga that is passed in.
// When authentication is not successful we route to the login screen.
// export const checkAuthentication = (saga, check) => {
//   return function * checkAuthentication () {
//     const isAuthenticated = yield call(check)
//     if (isAuthenticated) {
//       if (saga) {
//         yield spawn(saga)
//       }
//     } else {
//       yield put(addMessage(LOGIN_MESSAGE, 'Please login to continue.'))
//       yield put(routeLogin())
//     }
//   }
// }

// export function * init () {
//   // If there already is a valid token in localStorage we can just send the user directly to find.
//   const [isOwner, isAdmin] = yield all([
//     call(ownerAuthFilter),
//     call(adminAuthFilter)
//   ])
//   // Owner goes to find.
//   if (isOwner) {
//     yield put(routeFind())
//   }
//   // Admin goes to admin.
//   if (isAdmin) {
//     yield put(adminNavigateDashboard())
//   }
//   yield call(checkRememberMe)
//   yield takeEvery(PERFORM_LOGIN, attemptLogin)
//   yield takeEvery(DECLINED_TERMS_CONDITIONS, termsDeclined)
// }
