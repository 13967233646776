
import {fork, takeLatest} from 'redux-saga/effects'
import {AdminCrudSagas} from './shared'
import {
  adminFetchDGraphs,
} from 'actions'
import {
  ADMIN_FETCH_DGRAPHS,
  ADMIN_UPDATE_DGRAPHS,
  ADMIN_CREATE_DGRAPHS,
  ADMIN_DELETE_DGRAPHS,
} from 'types'

class DGraphSagas extends AdminCrudSagas {
}
const sagas = new DGraphSagas({
  modelNames: { singular: 'dGraph', plural: 'dGraphs' },
  endpoint: 'crud/d-graphs',
  fetchAction: adminFetchDGraphs,
  preFetchActions: [],
  updateMethod: 'patch',
  apiVersion: 'v2'
})

export function * init () {
  yield fork(function * () { yield takeLatest(ADMIN_FETCH_DGRAPHS, sagas.fetch.bind(sagas)) })
  yield fork(function * () { yield takeLatest(ADMIN_UPDATE_DGRAPHS, sagas.update.bind(sagas)) })
  yield fork(function * () { yield takeLatest(ADMIN_CREATE_DGRAPHS, sagas.create.bind(sagas)) })
  yield fork(function * () { yield takeLatest(ADMIN_DELETE_DGRAPHS, sagas.delete.bind(sagas)) })
}

export const routeTrigger = sagas.routeTrigger.bind(sagas)
