/**
 * Added this to be able to work with necessary Appcues functions
 * getting them from window object
 */
declare global {
  interface Window {
    Appcues: {
      identify: (id: string, properties?: object) => void
      reset: () => void
    }
  }
}

/**
 * Identify user with his email for Appcues
 * @param userName
 */
export const identifyUserInAppcues = (userName: string) => {
  if (window) {
    const { Appcues } = window
    if (Appcues) {
      Appcues.identify(userName)
    }
  }
}

/**
 * Clean Appcues user data
 */
export const resetUserForAppcues = () => {
  if (window) {
    const { Appcues } = window
    if (Appcues) {
      Appcues.reset()
    }
  }
}
