import { fork, takeLatest, put, call } from 'redux-saga/effects'
import api from 'state/utils/api'
import { AdminCrudSagas } from './shared'
import {
  adminFetchPendingAccounts,
  adminFetchMarkets,
  adminApiCallFailed,
  adminPushIsBusy,
  adminPopIsBusy,
} from 'actions'
import { AdminError } from 'state/reducers/admin/errors'
import {
  ADMIN_FETCH_PENDING_ACCOUNTS,
  ADMIN_CREATE_PENDING_ACCOUNT,
  ADMIN_UPDATE_PENDING_ACCOUNT,
  ADMIN_DELETE_PENDING_ACCOUNT,
  ADMIN_VERIFY_PENDING_ACCOUNT,
} from 'types'

class PendingAccountsSagas extends AdminCrudSagas {
  *update(action) {
    const { modelId } = action.payload
    yield super.update(action, {
      method: 'post',
      endpoint: `admin/pending-accounts/${modelId}/update`,
    })
  }
  *verifyPendingAccount(action) {
    let model = action.payload.model
    const next = action.payload.next
    try {
      yield put(adminPushIsBusy())
      const response = yield call(
        [api, 'post'],
        `/admin/pending-accounts/${model._id}/convert`,
        model
      )

      yield put(adminPopIsBusy())

      if (response.oktaStatus === 'error') {
        yield put(
          adminApiCallFailed(
            new AdminError('Okta account could not be created', {
              message:
                'Sorry, something went wrong while creating the user account in Okta',
            })
          )
        )
        return
      }

      yield next(response.oktaStatus)
    } catch (error) {
      yield put(adminPopIsBusy())
      yield put(
        adminApiCallFailed(
          new AdminError(`Create ${this.modelNames.singular}`, error)
        )
      )
    }
  }
}
const sagas = new PendingAccountsSagas({
  modelNames: { singular: 'pendingAccount', plural: 'pendingAccounts' },
  endpoint: 'crud/pending-accounts',
  fetchAction: adminFetchPendingAccounts,
  preFetchActions: [adminFetchMarkets],
  updateMethod: 'patch',
  apiVersion: 'v2',
})

export function* init() {
  yield fork(function* () {
    yield takeLatest(ADMIN_FETCH_PENDING_ACCOUNTS, sagas.fetch.bind(sagas))
  })
  yield fork(function* () {
    yield takeLatest(ADMIN_CREATE_PENDING_ACCOUNT, sagas.create.bind(sagas))
  })
  yield fork(function* () {
    yield takeLatest(ADMIN_UPDATE_PENDING_ACCOUNT, sagas.update.bind(sagas))
  })
  yield fork(function* () {
    yield takeLatest(ADMIN_DELETE_PENDING_ACCOUNT, sagas.delete.bind(sagas))
  })
  yield fork(function* () {
    yield takeLatest(
      ADMIN_VERIFY_PENDING_ACCOUNT,
      sagas.verifyPendingAccount.bind(sagas)
    )
  })
}

export const routeTrigger = sagas.routeTrigger.bind(sagas)
