import { fork, takeLatest, call, put } from 'redux-saga/effects'
import { AdminCrudSagas, buildFetchQuery, next } from './shared'
import {
  adminFetchVehicles,
  adminFetchPricingTiers,
  adminApiCallFailed,
  adminFetchVehiclesWithCanModifyAvailability,
  adminNavigateVehicles,
  adminNavigatePendingVehicles,
  adminPushIsBusy,
  adminPopIsBusy,
} from 'actions'
import {
  ADMIN_FETCH_VEHICLES,
  ADMIN_CREATE_VEHICLE,
  ADMIN_UPDATE_VEHICLE,
  ADMIN_DELETE_VEHICLE,
  ADMIN_CREATE_REFRESH_VEHICLE,
  ADMIN_FETCH_VEHICLES_WITH_CAN_MODIFY_AVAILABILITY,
  ADMIN_APPROVE_VEHICLE,
} from 'types'
import { AdminError } from 'state/reducers/admin/errors'
import {
  UAxleType,
  UBodyType,
  UFuelType,
  ULiftGateType,
  URoofHeight,
  USleeperBed,
  UVehicleSize,
} from '@cpbtechnology/coop-shared/types/vehicle'
import { trimDoubleSlash } from '../../../utils'

const printRequiredFields = {
  'details.make': 'Make',
  'details.model': 'Model',
  'details.year': 'Year',
  location: 'Location',
  company: 'Company',
  'licensePlate.state': 'State',
  'licensePlate.num': 'Plate num',
  'availabilityBlocked.reason': 'Block Reason',
  'availabilityBlocked.relistIndications': 'Relist indications',
  'availabilityBlocked.otherReason': 'Block reason',
  'availabilityBlocked.otherReListIndications': 'Relist indications',
  bodyType: 'Body type',
  axleType: 'Axle type',
  sleeperBed: 'Sleeper bed',
  liftGateType: 'Lift Gate',
  fuelType: 'Fuel type',
  roofHeight: 'Roof height',
  length: 'Vehicle length',
}

// TODO: whaaaat is going on here?
// 1. why not just override fetch in VehiclesSagas? why the different (and now obsolete) name + action?
// 2. wtf is up with the DEPRECATING stuff?
// 3. i see the endpoints for update and delete don't follow the crud pattern,
//    but for fetch can't we just rely a custom endpoint (as in VehicleSagasAdapter) and be done with it?
class VehiclesSagas extends AdminCrudSagas {
  getPropertyValue(model, property) {
    const properties = property.split('.')
    switch (properties.length) {
      case 1:
        return model[property]
      case 2:
        return model[properties[0]][properties[1]]
      default:
        return undefined
    }
  }

  *verifyVehicle(action) {
    let model = action.payload.model
    const { documentation } = model
    let isValid = true
    if (documentation) {
      const hasMissingExpirationDates = documentation.some((doc) => {
        return doc.expiresAt === undefined || doc.expiresAt === ''
      })
      if (hasMissingExpirationDates) {
        isValid = false
        yield put(
          adminApiCallFailed(
            new AdminError(`Invalid Vehicle Fields`, {
              message:
                'Make sure all Vehicle documents have an expiration date',
            })
          )
        )
      }
    }

    if (!model.assetType) {
      yield put(
        adminApiCallFailed(
          new AdminError(`Invalid Vehicle Fields`, {
            message: `Make sure you provide an assetType`,
          })
        )
      )
      return false
    }

    const requiredFields = [
      'details.make',
      'details.model',
      'details.year',
      'location',
      'company',
      'licensePlate.state',
      'licensePlate.num',
    ]

    const { assetType, size, axleType } = model

    if (UVehicleSize.isApplicableTo({ assetType }).isRequired) {
      requiredFields.push('length')
    }
    if (UBodyType.isApplicableTo({ assetType }).isRequired) {
      requiredFields.push('bodyType')
    }
    if (UAxleType.isApplicableTo({ assetType, size }).isRequired) {
      requiredFields.push('axleType')
    }
    if (USleeperBed.isApplicableTo({ assetType, axleType }).isRequired) {
      requiredFields.push('sleeperBed')
    }
    if (ULiftGateType.isApplicableTo({ assetType, size }).isRequired) {
      requiredFields.push('liftGateType')
    }
    if (UFuelType.isApplicableTo({ assetType }).isRequired) {
      requiredFields.push('fuelType')
    }
    if (URoofHeight.isApplicableTo({ assetType, size }).isRequired) {
      requiredFields.push('roofHeight')
    }
    if (
      model &&
      model.availabilityBlocked &&
      model.availabilityBlocked.isRelistBlocked
    ) {
      requiredFields.push('availabilityBlocked.reason')
      requiredFields.push('availabilityBlocked.relistIndications')
    }

    if (
      model &&
      model.availabilityBlocked &&
      model.availabilityBlocked.isRelistBlocked &&
      model.availabilityBlocked.reason === 'other'
    ) {
      requiredFields.push('availabilityBlocked.otherReason')
    }

    if (
      model &&
      model.availabilityBlocked &&
      model.availabilityBlocked.isRelistBlocked &&
      model.availabilityBlocked.relistIndications === 'other'
    ) {
      requiredFields.push('availabilityBlocked.otherReListIndications')
    }

    isValid = !requiredFields.some((requiredField) => {
      const value = this.getPropertyValue(model, requiredField)
      return !value
    })

    if (!isValid) {
      const missingFields = requiredFields
        .map((requiredField) => {
          const value = this.getPropertyValue(model, requiredField)
          return !value ? requiredField : false
        })
        .filter((requiredField) => !!requiredField)
      yield put(
        adminApiCallFailed(
          new AdminError(`Invalid Vehicle fields`, {
            message: `Make sure you provide the following required fields: ${missingFields.map(
              (field) => printRequiredFields[field]
            )}`,
          })
        )
      )
    }

    return isValid
  }

  *verifyDraftVehicle(action) {
    let model = action.payload.model
    let isValid = true

    if (!model.assetType) {
      yield put(
        adminApiCallFailed(
          new AdminError(`Invalid Vehicle Fields`, {
            message: `Make sure you provide an assetType`,
          })
        )
      )
      return false
    }

    const requiredFields = ['location', 'company']

    isValid = !requiredFields.some((requiredField) => {
      const value = this.getPropertyValue(model, requiredField)
      return !value
    })

    if (!isValid) {
      const missingFields = requiredFields
        .map((requiredField) => {
          const value = this.getPropertyValue(model, requiredField)
          return !value ? requiredField : false
        })
        .filter((requiredField) => !!requiredField)
      yield put(
        adminApiCallFailed(
          new AdminError(`Invalid Vehicle fields`, {
            message: `Make sure you provide the following required fields: ${missingFields.map(
              (field) => printRequiredFields[field]
            )}`,
          })
        )
      )
    }

    return isValid
  }

  // TODO: Evaluate usage of CanModifyAvailability flag, since this might be deprecated
  // After recent change in vehicle availability from explicit ranges to blocked dates.
  // Restrictions are being handle by the calendar component & vehicle editor implementation.
  *fetchWithCanModifyAvailability(action) {
    const params = action.payload
    let response
    let url = `/admin/vehicles/${params.modelId}`
    try {
      let fetchQuery
      fetchQuery = yield buildFetchQuery()
      response = yield call([this.api, 'get'], url, fetchQuery)
      let successPayload = {
        response,
        collectionName: this.modelNames.plural,
      }
      if (action.payload) {
        // pass on the request id if there was one
        successPayload.requestId = action.payload.requestId
      }
      yield put(this.fetchSuccessAction(successPayload))
      yield next(action)
    } catch (error) {
      yield put(
        adminApiCallFailed(
          new AdminError(`Fetch ${this.modelNames.plural}`, error)
        )
      )
    }
  }

  *createAndRefresh(action) {
    let model = action.payload.model
    try {
      let isValid = false
      if (model.isDraft) {
        isValid = yield this.verifyDraftVehicle(action)
      } else {
        isValid = yield this.verifyVehicle(action)
      }

      if (isValid) {
        yield put(adminPushIsBusy())
        const response = yield call(
          [this.api, 'post'],
          trimDoubleSlash(`/${this.endpoint}/`),
          model
        )
        yield put(adminPopIsBusy())
        // Redirect to vehicle grid by status of admin approval
        if (response.isPendingAdminApproval) {
          yield put(adminNavigatePendingVehicles({ modelId: response._id }))
        } else {
          yield put(adminNavigateVehicles({ modelId: response._id }))
        }
      }
    } catch (error) {
      yield put(adminPopIsBusy())
      yield put(
        adminApiCallFailed(
          new AdminError(`Create ${this.modelNames.singular}`, error)
        )
      )
    }
  }

  *approve(action) {
    let model = action.payload.model
    try {
      const isValid = yield this.verifyVehicle(action)
      if (isValid) {
        yield put(adminPushIsBusy())
        yield call(
          [this.api, 'post'],
          trimDoubleSlash(`/${this.endpoint}/${model._id}/approve`),
          model
        )
        yield put(adminPopIsBusy())
        yield put(adminNavigateVehicles())
      }
    } catch (error) {
      yield put(adminPopIsBusy())
      yield put(
        adminApiCallFailed(
          new AdminError(`Approve ${this.modelNames.singular}`, error)
        )
      )
    }
  }
}

class VehicleSagasAdapter extends VehiclesSagas {
  constructor(options = {}) {
    super(options)
    let { endpoint = '/admin/vehicles' } = options

    this.endpoint = endpoint // override

    return this
  }

  *update(action) {
    // override
    let model = action.payload.model
    let isValid = false
    if (model.isDraft) {
      isValid = yield this.verifyDraftVehicle(action)
    } else {
      isValid = yield this.verifyVehicle(action)
    }

    if (isValid) {
      let { modelId: id } = action.payload
      let endpoint = trimDoubleSlash(`${this.endpoint}/${id}/update`)
      yield super.update(action, { method: 'post', endpoint })
    }
  }

  *delete(action) {
    // override
    let { modelId: id } = action.payload
    let endpoint = trimDoubleSlash(`${this.endpoint}/${id}/delete`)
    yield super.delete(action, { method: 'post', endpoint })
  }
}

const options = {
  modelNames: { singular: 'vehicle', plural: 'vehicles' },
  fetchAction: ({ modelId, ...rest }) => {
    if (modelId) {
      return adminFetchVehiclesWithCanModifyAvailability({ modelId, ...rest })
    } else {
      return adminFetchVehicles({ modelId, ...rest })
    }
  },
  preFetchActions: [adminFetchPricingTiers],
  apiVersion: 'v2',
}
const options_DEPRECATING = {
  ...options,
  endpoint: 'crud/vehicles',
  updateMethod: 'patch',
}
const DEPRECATING = new VehiclesSagas(options_DEPRECATING)
const sagas = new VehicleSagasAdapter(options)

export function* init() {
  yield fork(function* () {
    yield takeLatest(ADMIN_FETCH_VEHICLES, DEPRECATING.fetch.bind(DEPRECATING))
  })
  yield fork(function* () {
    yield takeLatest(ADMIN_CREATE_VEHICLE, DEPRECATING.create.bind(DEPRECATING))
  })
  yield fork(function* () {
    yield takeLatest(ADMIN_UPDATE_VEHICLE, sagas.update.bind(sagas))
  })
  yield fork(function* () {
    yield takeLatest(ADMIN_DELETE_VEHICLE, sagas.delete.bind(sagas))
  })
  yield fork(function* () {
    yield takeLatest(
      ADMIN_CREATE_REFRESH_VEHICLE,
      sagas.createAndRefresh.bind(DEPRECATING)
    )
  })
  yield fork(function* () {
    yield takeLatest(ADMIN_APPROVE_VEHICLE, sagas.approve.bind(sagas))
  })
  yield fork(function* () {
    yield takeLatest(
      ADMIN_FETCH_VEHICLES_WITH_CAN_MODIFY_AVAILABILITY,
      DEPRECATING.fetchWithCanModifyAvailability.bind(DEPRECATING)
    )
  })
}

export const routeTrigger = DEPRECATING.routeTrigger.bind(DEPRECATING)
