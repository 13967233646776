import { EApiSlice } from '@cpbtechnology/coop-shared/api'
import {
  ActionMeta,
  PaginatorSsl,
} from '@cpbtechnology/coop-shared/types/action'
import {
  adminApiCallFailed,
  adminFetchCompaniesNamesSucceeded,
  adminFetchReservationsOptimizedSucceeded,
  adminSetIsFetchingReservationsOptimized,
} from 'actions'
import { EGlobalFiltersNames } from 'components/ReservationsTable/types'
import { call, fork, put, select, takeLatest } from 'redux-saga/effects'
import { AdminError } from 'state/reducers/admin/errors'
import api from 'state/utils/api'
import { api as coopApi } from 'state/utils/api-v3'
import {
  ADMIN_FETCH_COMPANIES_NAMES,
  ADMIN_FETCH_RESERVATIONS_OPTIMIZED,
} from 'types'
import { AdminCrudSagas, next } from './shared'

export const fetchReservations = async ({ filters, paginator, requestId }) => {
  return coopApi.get({
    path: 'reservations/admin-table-view',
    slice: EApiSlice.admin,
    params: { ...filters },
    meta: new ActionMeta({
      requestId,
      paginator,
      sort: {},
      match: {},
    }),
    requestId,
  })
}

/**
 * Format filters to be sent to request, apply hash priority rule.
 */
const getFilters = (filters) => {
  // Hash has priority in case hash is provided, don't take into account the other filters.
  const hash = filters.find(({ id }) => id === EGlobalFiltersNames.hash)
  const filterValuesToQuery = {}
  if (hash && hash.value) {
    filterValuesToQuery.hash = hash.value
    return filterValuesToQuery
  }

  const company = filters.find(({ id }) => id === EGlobalFiltersNames.company)
  if (company && company.value) {
    filterValuesToQuery.lenderOrBorrowerId = company.value
  }

  const state = filters.find(({ id }) => id === EGlobalFiltersNames.state)
  if (state && state.value) {
    filterValuesToQuery.state = state.value
  }

  const customerVehicleNumber = filters.find(
    ({ id }) => id === EGlobalFiltersNames.customerVehicleNumber
  )
  if (customerVehicleNumber && customerVehicleNumber.value) {
    filterValuesToQuery.customerVehicleNumber = customerVehicleNumber.value
  }

  return filterValuesToQuery
}

class reservationsOptimizedSagas extends AdminCrudSagas {
  *fetchOptimized(action) {
    yield put(adminSetIsFetchingReservationsOptimized(true))

    try {
      const globalFilters = yield select(
        (state) => state.admin.reservationsOptimized.filters
      )
      const reservationPaginator = yield select(
        (state) => state.admin.reservationsOptimized.reservationPaginator
      )
      const filters = getFilters(globalFilters)
      const paginator = new PaginatorSsl(reservationPaginator)
      const requestId = paginator ? paginator.requestId : ''
      const response = yield call(fetchReservations, {
        filters,
        paginator,
        requestId,
      })

      const { data } = response

      yield put(adminFetchReservationsOptimizedSucceeded(data))
      yield next(action)
    } catch (error) {
      yield put(
        adminApiCallFailed(
          new AdminError(`Fetch Reservations`, error)
        )
      )
    }
    yield put(adminSetIsFetchingReservationsOptimized(false))
  }

  *fetchCompaniesNames(action) {
    try {
      const response = yield call([api, 'get'], '/admin/companies/fetch-names')
      yield put(adminFetchCompaniesNamesSucceeded(response))
      yield next(action)
    } catch (error) {
      yield put(
        adminApiCallFailed(
          new AdminError(`Fetch Companies names`, error)
        )
      )
    }
  }

  *routeTrigger() {}
}

const sagas = new reservationsOptimizedSagas({
  modelNames: { singular: 'reservation opt', plural: 'reservations opt' },
})

export function* init() {
  yield fork(function* () {
    yield takeLatest(
      ADMIN_FETCH_COMPANIES_NAMES,
      sagas.fetchCompaniesNames.bind(sagas)
    )
  })
  yield fork(function* () {
    yield takeLatest(
      ADMIN_FETCH_RESERVATIONS_OPTIMIZED,
      sagas.fetchOptimized.bind(sagas)
    )
  })
}

export const routeTrigger = sagas.routeTrigger.bind(sagas)
