import { all, call, put, takeEvery } from 'redux-saga/effects'
import queryString from 'query-string'
import {
  ownerAuthFilter,
  superAuthFilter,
  adminGroupAuthFilter,
  everyoneAuthFilter,
  attemptServerAuth,
  attemptServerAuthWithIdToken,
  handleServerAuthSucceeded,
} from './authentication'
import {
  addMessage,
  removeMessage,
  loginSuccess,
  removeToggle,
  adminNavigateReservationsOptimized,
  performLogout,
} from 'actions'
import {
  PERFORM_LOGIN,
  LOGIN_MESSAGE,
  REMEMBER_ME,
  SUBMIT_REGISTER_FORM_REQUEST,
  SNACKBAR,
  TOAST,
  PERFORM_LOGIN_WITH_IDTOKEN,
  OKTA_LOGIN_FAILED,
} from 'types'
import { post } from 'state/utils/api'
import Cookies from 'universal-cookie'

import CoopRouteSagas from './CoopRouteSagas'
import { clearTokens } from '../../utils/okta'

// Runs when the login form is submitted.
export function* attemptLogin({ payload }) {
  try {
    const loginResponse = yield attemptServerAuth(payload)

    yield handleServerAuthSucceeded(loginResponse)

    yield call(updateRememberMe, rememberMe, username)
    yield put(loginSuccess())

    const search = window.location.search
    const parsed = queryString.parse(search)

    window.location = parsed.redirect || '/admin/reservations-opt'

    return window.location
  } catch (e) {
    const message =
      e.response && e.response.code === 'Unauthorized'
        ? 'Invalid email or password'
        : e.message
        ? `Error: ${e.message}`
        : 'An Unknown Error Occurred'
    yield put(addMessage(LOGIN_MESSAGE, message))
  }
}

// Runs when we get the idToken from Okta
export function* attemptLoginWithIdToken({ payload }) {
  try {
    const { idToken, redirect } = payload
    const loginResponse = yield attemptServerAuthWithIdToken({ idToken })

    yield handleServerAuthSucceeded(loginResponse)

    yield put(loginSuccess())

    // Not ideal at all, but when running yield push(), the sagas are not firing when the view loads. //yield replace(`${redirect}`)
    window.location = (redirect && redirect !== '/') ? redirect : '/admin/reservations-opt'
    return window.location
  } catch (e) {
    const message =
      e.message || 'An Unknown Error Occurred'
    yield put(addMessage('login-with-idtoken', message, SNACKBAR))
    yield new Promise((resolve) => setTimeout(resolve, 2000))
    yield put(performLogout())
  }
}

export function* oktaLoginFailed({ payload }) {
  clearTokens()
  yield put(addMessage('okta-login-failed', payload.message, TOAST, 'Go to Okta', process.env.REACT_APP_OKTA_DOMAIN))
}

// LOGIN : Functionality for the login route.
// Set or remove the username from localStorage, then always attempt to remove the message
// containing the username.
function* updateRememberMe(rememberMe, username) {
  if (rememberMe) {
    yield window.localStorage.setItem(REMEMBER_ME, username)
  } else {
    yield window.localStorage.removeItem(REMEMBER_ME)
  }
  yield put(removeMessage(REMEMBER_ME))
}

// Check whether we have a username in localStorage, if so put it in our messages so our view can
// use it.
function* checkRememberMe() {
  const localUsername = yield window.localStorage.getItem(REMEMBER_ME)
  if (localUsername) {
    yield put(addMessage(REMEMBER_ME, localUsername))
  }
}

export function* submitEmailSignupForm({ payload }) {
  const {
    firstName,
    lastName,
    company,
    title,
    phone,
    email,
    zipCode,
    toggleValue,
  } = payload
  const cookies = new Cookies()
  const hutk = cookies.get('hubspotutk') || null
  const url = window.location.href

  try {
    yield call(post, '/contact-form', {
      firstname: firstName,
      lastname: lastName,
      company,
      jobtitle: title,
      phone,
      email,
      zip: zipCode,
      hutk,
      url,
    })

    yield put(
      addMessage(
        'email-signup-success',
        'Thanks for your interest in the Ryder Coop Beta program. We will be in contact soon.',
        SNACKBAR
      )
    )
    yield put(removeToggle(toggleValue))
  } catch (e) {
    yield put(
      addMessage(
        'email-signup-success-error',
        'Unable to submit your information, please try again.',
        SNACKBAR
      )
    )
  }
}

export function* watchForSubmitRegisterForm() {
  yield takeEvery(SUBMIT_REGISTER_FORM_REQUEST, submitEmailSignupForm)
}

export class LoginRouteSagas extends CoopRouteSagas {
  constructor() {
    super()
    this.authFilter = everyoneAuthFilter
  }

  *initListeners() {
    // If there already is a valid token in localStorage we can just send the user directly to find.
    const [, isAdmin, isSuper] = yield all([
      call(ownerAuthFilter),
      call(adminGroupAuthFilter),
      call(superAuthFilter),
    ])
    // Admin goes to admin.
    if (isAdmin || isSuper) {
      yield put(adminNavigateReservationsOptimized())
    }
    yield call(checkRememberMe)
    yield takeEvery(PERFORM_LOGIN, attemptLogin)
    yield takeEvery(PERFORM_LOGIN_WITH_IDTOKEN, attemptLoginWithIdToken)
    yield takeEvery(OKTA_LOGIN_FAILED, oktaLoginFailed)
  }

  *handleRouteHit() {}
}
