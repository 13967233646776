import 'babel-polyfill'
import 'whatwg-fetch'
import 'react-loading-skeleton/dist/skeleton.css'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createRoot } from 'react-dom/client'
import { store } from 'state/store'
import App from 'view/App'
import a11yRules from './a11yRules'

if (process.env.REACT_APP_RUN_ACCESSIBILITY === 'true') {
  const a11y = require('react-a11y').default
  a11y(React, ReactDOM, {
    rules: a11yRules,
  })
}

const Application = (App) => (
  <Provider store={store}>
    <App />
  </Provider>
)

const container = document.getElementById('root')
const root = createRoot(container)
root.render(Application(App))

if (module.hot) {
  module.hot.accept('view/App', () => {
    const NextApp = require('view/App').default
    root.render(Application(NextApp))
  })
}
