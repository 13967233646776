
import {fork, takeLatest} from 'redux-saga/effects'
import {AdminCrudSagas} from './shared'
import {
  adminFetchModelValidations
} from 'actions'
import {
  ADMIN_FETCH_MODEL_VALIDATIONS
} from 'types'

class ModelValidationsSagas extends AdminCrudSagas { }
const sagas = new ModelValidationsSagas({
  modelNames: { singular: 'Data Issue', plural: 'Data Issues' },
  endpoint: 'utilities/model-validations',
  fetchAction: adminFetchModelValidations,
  apiVersion: 'v2'
})

export function * init () {
  yield fork(function * () { yield takeLatest(ADMIN_FETCH_MODEL_VALIDATIONS, sagas.fetch.bind(sagas)) })
}

export const routeTrigger = sagas.routeTrigger.bind(sagas)
