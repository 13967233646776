import {handleActions} from 'redux-actions'

import {
  ADMIN_API_CALL_FAILED,
  ADMIN_DISMISS_ERRORS
} from 'types'

const initialState = []

export class AdminError {
  constructor (failedAction, error) {
    this.failedAction = failedAction
    // this is all pretty hacky.
    this.message = error.message
    this.actions = error.actions
    if (!this.message && ('response' in error) && ('message' in error.response)) {
      this.message = error.response.message
    }
    this.info = error.info
    if (!this.info && ('response' in error) && ('info' in error.response)) {
      this.info = error.response.info
    }
    if (!this.message) {
      this.message = 'Unknown Error'
    }
    this.dismissed = false
  }
}

export const reducer = handleActions({
  [ADMIN_API_CALL_FAILED]: (state, action) => state.concat(action.payload),
  [ADMIN_DISMISS_ERRORS]: (state, action) => []  // TODO
}, initialState)
