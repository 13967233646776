import {
  ApiHttpInterface,
  ApiHttpHost,
  ApiClientDeclaration,
  EApiClientName,
} from '@cpbtechnology/coop-shared/api'
import { IFetcher } from '@cpbtechnology/coop-shared/api/http/fetcher'
import { TOKEN } from 'types'

const loadAuthentication = () => ({
  loginToken: window.localStorage.getItem(TOKEN),
})

const Url = require('url-parse')

// get the base url of API from env variables
const baseURL = process.env.REACT_APP_API_URL

// Parse the url so we can get access to its components
const { hostname, port, protocol } = new Url(baseURL)

// Build the host info
const host = new ApiHttpHost({
  host: hostname,
  port,
  // We do this because the "protocol" is returned with a colon like "http:" instead of just "http"
  protocol: protocol.slice(0, -1),
})

// Build the client declaration
const client = new ApiClientDeclaration({
  name: EApiClientName.webAdmin,
})

// Try to load current authentication info (if available)
const auth = loadAuthentication()

// Build the http interface
export const api = new ApiHttpInterface({
  client,
  host,
  authToken:
    auth !== null && auth.loginToken !== null ? auth.loginToken : undefined,
  // Feels weird to do this but seems it is the only way to force TS understand that
  // `window.fetch` is indeed a valid implementation of the "fetch" standard
  fetcher: window as unknown as IFetcher,
})

/**
 * Wrapper that allows setting the authentication token of the underlying api client.
 */
export const setAuthToken = (authToken: string) => {
  api.authToken = authToken
}
