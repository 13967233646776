import React from 'react'
import styled from 'styled-components'

import {getTheme, media} from 'view/theme'

import MaterialCard from 'material-ui/Card'
import MaterialList, {ListItem as MaterialListItem} from 'material-ui/List'
import MaterialTextField from 'material-ui/TextField'
import {FormControl as MaterialFormControl} from 'material-ui/Form'
import Typography from 'material-ui/Typography'
import Grid from 'material-ui/Grid'
import {DialogContent as MaterialDialogContent} from 'material-ui/Dialog'
import MaterialIconButton from 'material-ui/IconButton'

// GLOBAL WRAPPER
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

export const FlexWrapper = styled.div`
  flex: 1 0 auto;
`

// STANDARD MAIN ELEMENT
export const Main = styled.main`
  align-items: flex-start;
  display: flex;
`

// FIND VIEW MAIN ELEMENT
export const MainFind = styled(Main)`
  height: 100%;
  overflow-y: hidden;
`

// STANDARD LOADING SPINNER WRAPPER
export const SpinnerWrapper = styled(Wrapper)`
  align-items: center;
  height: 100vh;
  justify-content: center;
`

// STANDARD CONTENT WRAPPER
export const ContentWrapper = styled.section`
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  padding: 0 ${getTheme('halfSpace')};
  width: 100%;

  ${media.lg`
    margin-left: auto;
    margin-right: auto;
    max-width: 66rem;
    padding: 0;
  `}
`

// STANDARD TOOLBAR
export const Toolbar = styled.div`
  border-bottom: 1px solid ${getTheme('grays', 'lighter')};
  display: flex;
  justify-content: space-between;
  padding: 0 ${getTheme('quarterSpace')};

  ${media.mediumUp`
    padding: ${getTheme('eighthSpace')} ${getTheme('halfSpace')};
  `}
`

export const TextWrapper = styled.section`
  background-color: ${getTheme('white')};
  margin-left: auto;
  margin-right: auto;
  padding: ${getTheme('space')};
  padding-top: 0;
  max-width: 55rem;
`

// STANDARD PAGE TITLE
export const PageHeading = styled(Typography)`
  color: ${getTheme('grays', 'dark')} !important;
  font-size: 2.25rem !important;
  font-weight: 700 !important;
  letter-spacing: 2px;
  line-height: 1 !important;
  margin-bottom: ${getTheme('halfSpace')} !important;
  margin-top: ${getTheme('halfSpace')} !important;
  text-transform: none !important;
`

// STANDARD PAGE SUBNAV
export const SubNav = styled.nav`
  padding-bottom: ${getTheme('space')};

  ul {
    display: flex;
  }

  li {
    margin-right: ${getTheme('halfSpace')};
    position: relative;

    ${media.mediumUp`
      margin-right: ${getTheme('doubleSpace')};
    `}

    &.active {
      border-bottom: 4px solid ${getTheme('primary')};

      button {
        color: ${getTheme('black')};
        font-family: ${getTheme('headingFont')} !important;
      }
    }
  }

  button {
    background-color: transparent;
    border: none;
    color: ${getTheme('grays', 'medium')};
    font-family: ${getTheme('headingFont')} !important;
    font-size: .625rem;
    font-weight: 500;
    padding: 0;
    padding-bottom: ${getTheme('eighthSpace')};
    text-transform: uppercase;
    transition: color .2s ease-out;

    ${media.smallUp`
      font-size: .75rem;
    `}

    ${media.mediumUp`
      font-size: 1rem;
    `}

    &:hover {
      color: ${getTheme('primary')};
      cursor: pointer;
    }
  }
`

// STANDARD FLEX LIST WRAPPER
export const CardsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &:not(:last-child) {
    border-bottom: ${getTheme('borders', 'hairlineLight')};
    margin-bottom: ${getTheme('space')};
  }

  ${media.smallUp`
    justify-content: flex-start;
  `}

  ${media.lg`
    margin-left: -${getTheme('space')};
    margin-right: -${getTheme('space')};
  `}
`

// STANDARD HEADING
export const Headline = styled(Typography)`
  color: ${getTheme('secondary')} !important;
  display: flex !important;
  align-items: center !important;
  padding-left: ${getTheme('space')} !important;
  padding-bottom: ${getTheme('halfSpace')} !important;

  svg {
    margin-right: ${getTheme('halfSpace')} !important;
  }
`

// STANDARD HERO SECTION
export const HeroSection = styled.section`
  background-color: ${getTheme('grays', 'medium')};
  flex: 1 0 auto;
  padding-top: 5rem;

  ${media.mediumUp`
    padding-top: 1.5rem;
  `}
`

// STANDARD CONTENT SECTION
export const Section = styled.section`
  flex: 1 0 auto;
  padding-top: 5rem;

  ${media.mediumUp`
    padding-top: 1.5rem;
  `}
`

// STANDARD INSET CONTENT CONTAINER
export const ContentContainer = styled(
  ({padding, ...rest}) => <MaterialCard {...rest} />
)`
  padding: ${props => props.padding ? getTheme('halfSpace') : 'initial'} !important;
  margin-left: auto !important;
  margin-right: auto !important;

  p {
    max-width: 55rem;
  }
`

// STANDARD ACCORDION LIST
export const List = styled(MaterialList)`
  margin-left: ${getTheme('space')} !important;
  margin-right: ${getTheme('space')} !important;
`

export const HCardsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: ${getTheme('space')};
`

// STANDARD CARD STYLES
export const Card = styled(MaterialCard)`
  width: 100%;
`

export const HCard = styled(Card)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: ${getTheme('space')};
  padding: ${getTheme('halfSpace')};
  padding-right: 0;
  width: 18.5rem;

  ${media.smallUp`
    margin-right: ${getTheme('space')};
  `}

  ${media.mediumUp`
    align-items: center;
    flex-direction: row;
    margin-right: 0;
    width: 100%;
  `}

  .hcard__content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${media.mediumUp`
      align-items: center;
      flex-basis: 80%;
      flex-direction: row;
      justify-content: flex-start;
      margin-right: 0;
    `}

    > div {
      margin-bottom: ${getTheme('halfSpace')};

      ${media.mediumUp`
        margin-bottom: 0;
      `}
    }
  }

  h3 {
    font-size: 1.125rem;
    line-height: 1.125;
  }

  .img-wrapper {
    flex: 0 0 auto;

    ${media.mediumUp`
      flex: 1 0 10%;
    `}
  }

  .name-wrapper {
    flex: 0 0 auto;
    padding-left: ${getTheme('halfSpace')};
    padding-right: ${getTheme('halfSpace')};

    ${media.mediumUp`
      flex: 1 0 30%;
      padding-left: ${getTheme('space')};
    `}
  }

  .earnings-wrapper {
    flex: 0 0 auto;
    padding-left: ${getTheme('halfSpace')};
    padding-right: ${getTheme('halfSpace')};

    ${media.mediumUp`
      flex: 1 0 25%;
      padding: 0;
    `}
  }

  .availability-wrapper {
    flex: 0 0 auto;
    padding-left: ${getTheme('halfSpace')};
    padding-right: ${getTheme('halfSpace')};

    ${media.mediumUp`
      flex: 1 0 32.5%;
      padding: 0;
    `}
  }
`

export const HCardCTAs = styled.div`
  align-items: center;
  display: flex;

  ${media.mediumUp`
    flex: 1 0 25%;
    justify-content: flex-start;
    padding-left: ${getTheme('halfSpace')};
  `}

  button {
    margin-bottom: 0;
    margin-right: ${getTheme('halfSpace')};

    &:last-child {
      margin-right: 0;
    }
  }
`

export const CardImgButton = styled.button`
  background: transparent;
  border: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
`

export const CardImg = styled.img`
  display: block;
  margin-bottom: ${getTheme('quarterSpace')};
  margin-left: auto;
  margin-right: auto;
  max-height: 10rem;
  width: auto;
`

export const HCardImg = styled.img`
  max-height: 4rem;
  width: auto;
`

export const CardContent = styled.div`
  padding: 0;
  width: 100%;

  p {
    align-items: center;
    display: flex;
    font-size: .875rem;
    margin-bottom: ${getTheme('quarterSpace')};

    svg {
      margin-right: ${getTheme('quarterSpace')};
    }
  }
`

export const CardTitle = styled(Typography)`
  color: ${getTheme('grays', 'darker')} !important;
  font-size: 1.125rem !important;
  font-weight: 500 !important;
  line-height: 1 !important;
  margin-bottom: ${getTheme('quarterSpace')} !important;

  span:first-of-type {
    padding-left: ${getTheme('quarterSpace')};
    padding-right: ${getTheme('quarterSpace')};
  }

  span:nth-of-type(2) {
    color: ${getTheme('grays', 'medium')} !important;
    font-weight: 400 !important;
    text-transform: none;
  }
`

export const HCardTitle = styled(Typography)`
  font-size: 1.125rem !important;
  font-weight: 700 !important;
  margin-bottom: ${getTheme('eighthSpace')};
`

export const CardSubtitle = styled(Typography)`
  color: ${getTheme('grays', 'darkest')} !important;
  font-family: ${getTheme('headingFont')} !important;
  font-size: .75rem !important;
  font-weight: 500 !important;
  line-height: 1.125 !important;
  margin-bottom: ${getTheme('quarterSpace')} !important;
`

export const CartTertiaryTitle = styled(CardSubtitle)`
  font-size: .625rem;
  font-weight: normal !important;
  margin-bottom: ${getTheme('eighthSpace')} !important;
  text-transform: uppercase;
`

export const CardHeading = styled(Typography)`
  font-size: .875rem !important;
  line-height: 1.25 !important;
  margin-bottom: ${getTheme('quarterSpace')} !important;

  strong {
    color: ${getTheme('secondary')} !important;
  }

  .strong-block {
    display: block;
  }
`

export const CardDataItem = styled(Typography)`
  color: ${getTheme('black')} !important;
  font-size: .875rem !important;
`

export const CardBodyBig = styled(Typography)`
  color: ${getTheme('black')} !important;
  font-family: ${getTheme('headingFont')} !important;
  font-size: 1rem !important;
  font-weight: 700 !important;
  line-height: 1.125 !important;
  text-align: center;

  small {
    display: block;
    font-size: .75rem;
    margin-top: 2px;
  }

  .earn {
    font-size: .625rem;
    text-transform: uppercase;
  }

  .day {
    display: block;
    font-size: .625rem;
    font-weight: 400;
    margin-left: -.25rem;
  }

  .contact-for-pricing {
    display: inline-block;
    font-size: .625rem;
    font-weight: 500;
    line-height: 1.2;
  }
`

export const CardBodyMedium = styled(Typography)`
  color: ${getTheme('grays', 'darkest')} !important;
  font-size: 1.25rem !important;
  font-weight: 700 !important;
  letter-spacing: 1px;
  margin-bottom: ${getTheme('quarterSpace')} !important;
`

export const CardBodySmall = styled(Typography)`
  color: ${getTheme('grays', 'dark')} !important;
  font-size: .75rem !important;
`

// VEHICLE DETAILS STYLES
export const WrapperGrid = styled(Grid)`
  justify-content: center;

  ${media.mediumUp`
    justify-content: space-between;
  `}
`

export const ContentGrid = styled(Grid)`
  max-width: 35rem !important;

  ${media.mediumUp`
    margin-left: auto !important;
    margin-right: auto !important;
  `}
`

export const ConstrainedDetailsGrid = styled(Grid)`
  flex-basis: auto !important;
  max-width: 100% !important;
  width: 100%;
`

export const HeroGrid = styled(ConstrainedDetailsGrid)`
  border-bottom: ${getTheme('borders', 'hairlineLight')};
  flex-wrap: nowrap !important;
  margin-bottom: ${getTheme('halfSpace')} !important;
  padding-bottom: ${getTheme('halfSpace')};
`

export const ImgWrapper = styled(Grid)`
  align-self: center;
`

export const CardImgUser = styled(CardImg)`
  cursor: pointer;
`

export const ImagesDots = styled.div`
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 66.66667%;
`

export const FlexAutoGrid = styled(Grid)`
  flex: 1 0 auto !important;
`

export const ActionCard = styled(Card)`
  background-color: ${getTheme('grays', 'lightest')} !important;
  margin-bottom: ${getTheme('space')};
  padding: ${getTheme('space')};

  ${media.mediumUp`
    margin-left: auto;
    margin-right: auto;
    max-width: 22rem;
  `}
`

export const ActionCardSection = styled.div`
  margin-bottom: ${getTheme('space')};

  p {
    align-items: center;
    display: flex;
    font-size: .875rem !important;
    font-weight: 700;
    margin-bottom: ${getTheme('quarterSpace')};

    svg {
      margin-right: ${getTheme('quarterSpace')};
    }
  }
`

// OVERRIDES FOR VEHICLE CARDS (FIND > VEHICLE LIST, BOOKING, MANAGE FLEET, BORROWING, LENDING)
export const ListItem = styled(MaterialListItem)`
  padding: 0 !important;
  width: 100% !important;

  ${media.smallUp`
    width: 50% !important;
  `}

  ${media.mediumUp`
    width: 100% !important;
  `}
`

export const SectionGrid = styled(Grid)`
  align-items: center;
  display: flex;
  padding-left: ${getTheme('quarterSpace')};
  padding-right: ${getTheme('quarterSpace')};
`

export const ConstrainedGrid = styled(Grid)`
  width: 15rem;

  @media (min-width: 960px) and (max-width: 1085px) {
    width: 100%;
  }
`

export const ColGridItem = styled(Grid)`
  flex-basis: auto !important;
`

export const CardDates = styled(CardSubtitle)`
  font-size: .75rem !important;
  font-weight: normal !important;
`

export const CardDatesError = styled(CardSubtitle)`
  color: ${getTheme('error')} !important;
`

export const DialogContent = styled(MaterialDialogContent)`
  margin: 0 auto;
  max-width: 30rem;
  overflow-y: visible !important;
  padding: ${getTheme('halfSpace')} !important;
  padding-top: 0 !important;
  width: 100%;
`

// STAMDARD CLOSE BUTTON WRAPPER
export const IconButton = styled(MaterialIconButton)`
  align-self: flex-end;
`

export const DetailsCardBodyBig = styled(CardBodyBig)`
  border-bottom: ${getTheme('borders', 'hairlineMedium')};
  padding: ${getTheme('halfSpace')};
  padding-top: 0;
`

export const DetailsGrid = styled(Grid)`
  margin: 0 !important;
  margin-bottom: ${getTheme('space')} !important;
`

export const TableGridHeading = styled(Grid)`
  padding-bottom: 0 !important;
`

export const FormHeader = styled.div`
  margin-bottom: ${getTheme('space')};
`

// STANDARD TEXT INPUT FIELD
export const TextField = styled(MaterialTextField)`
  margin-bottom: ${getTheme('space')} !important;
  margin-top: 0 !important;
  max-width: 40rem;

  > div:after {
    background-color: ${getTheme('primary')} !important;
  }

  label {
    &[data-shrink="true"] {
      color: ${getTheme('black')} !important;
    }
  }
`

// STANDARD FORM CONTROL
export const FormControl = styled(MaterialFormControl)`
  > div:after {
    background-color: ${getTheme('primary')} !important;
  }

  label {
    &[data-shrink="true"] {
      color: ${getTheme('black')} !important;
    }
  }
`

// UNIFIED STYLES FOR CLEAVE PHONE INPUTS
export const CleaveWrapper = styled.div`
  border: 0;
  display: inline-flex;
  flex-direction: column;
  margin: 0;
  margin-bottom: ${getTheme('space')};
  min-width: 0;
  padding: 0;
  position: relative;
  width: 100%;

  > label {
    color: rgba(0, 0, 0, 0.54);
    display: block;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    left: 0;
    line-height: 1;
    padding: 0;
    position: absolute;
    top: 0;
    transform: translate(0, 23px) scale(1);
    transform-origin: top left;
  }

  > div {
    align-items: baseline;
    color: rgba(0, 0, 0, 0.87);
    display: inline-flex;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    margin-top: 16px;
    position: relative;

    input {
      background: none;
      border: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
      box-shadow: none;
      box-sizing: content-box;
      color: currentColor;
      display: block;
      font: inherit;
      height: 1em;
      margin: 0;
      padding: 7px 0 9px;
      transition: background-color .2s cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      vertical-align: middle;
      width: 100%;

      &:focus {
        background-color: inherit;
        border-bottom: 2px solid ${getTheme('primary')};
        outline: 0;
      }
    }
  }
`
