import {handleActions} from 'redux-actions'
import { ADMIN_FETCH_ME_SUCCEEDED } from 'types'

const initialState = {
	_id: null,
	name: { first: '', last: '' },
	userName: '',
	market: null
}

export const reducer = handleActions({
  [ADMIN_FETCH_ME_SUCCEEDED]: (state, action) => action.payload.data
}, initialState)
