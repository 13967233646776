import { HeaderProps } from 'react-table'
import { IAdminUiReservationTableReservationJson } from './utils/types'

export enum EGlobalFiltersNames {
  hash = 'hash',
  state = 'state',
  company = 'company',
  customerVehicleNumber = 'customer-vehicle-number',
}

export enum ELocalFiltersNames {
  hash = 'hash',
  state = 'state',
  borrowerCompanyName = 'borrowerCompany.name',
  lenderCompanyName = 'lenderCompany.name',
  customerVehicleNumber = 'vehicle.customerVehicleNumber',
}

export interface ILocalFilter {
  id: ELocalFiltersNames
  value: string
}
export interface IGlobalFilter {
  id: EGlobalFiltersNames
  value?: string
}

export type CompanyName = {
  _id: string
  name: string
}

export type WithCompanyNames = {
  companies?: CompanyName[]
}

export type WithGlobalFilters = {
  globalFilters?: IGlobalFilter[]
}

export type WithDisableLocalFilter = {
  disableLocalFilter?: boolean
}

export type WithReservations = {
  reservations: IAdminUiReservationTableReservationJson[]
}

export type TableFilterProps =
  HeaderProps<IAdminUiReservationTableReservationJson>

export type Paginator =
  | {
      currentPage?: number | undefined
      itemsPerPage?: number | undefined
      totalItems?: number | undefined
      requestId?: string | undefined
    }
  | undefined
