// LOGIN & AUTHENTICATION
export const PERFORM_LOGIN = 'login/PERFORM_LOGIN'
export const PERFORM_LOGIN_WITH_IDTOKEN = 'login/PERFORM_LOGIN_WITH_IDTOKEN'
export const PERFORM_LOGOUT = 'login/PERFORM_LOGOUT'
export const ACCEPT_TERMS_CONDITIONS = 'login/ACCEPT_TERMS_CONDITIONS'
export const DECLINED_TERMS_CONDITIONS = 'login/DECLINED_TERMS_CONDITIONS'
export const LOGIN_SUCCESSFUL = 'login/LOGIN_SUCCESSFUL'
export const OKTA_LOGIN_FAILED = 'login/OKTA_LOGIN_FAILED'
export const LOGIN_FORM_TOGGLE = 'login/LOGIN_FORM_TOGGLE'
export const SHOW_EMAIL_SIGNUP = 'login/SHOW_EMAIL_SIGNUP'
export const SUBMIT_REGISTER_FORM_REQUEST = 'login/SUBMIT_REGISTER_FORM_REQUEST'
export const SHOW_EMAIL_SIGNUP_CONFIRMATION =
  'login/SHOW_EMAIL_SIGNUP_CONFIRMATION'

// LocalStorage Keys
export const TOKEN = 'coop-api-token'
export const PUBNUB_TOKEN = 'pubnub-api-token'
export const EXPIRES = 'coop-token-expiration'
export const REMEMBER_ME = 'coop-username'
export const TERMS_CONDITIONS = 'coop-terms-conditions'
export const USER_ID = 'coop-user-id'
export const ROLE = 'coop-user-role'

// ROUTES
export const ROUTE_LOGIN = 'route/ROUTE_LOGIN'
export const ROUTE_LOGIN_CALLBACK = 'route/ROUTE_LOGIN_CALLBACK'

// Toggle ID for the primary navigation.
export const PRIMARY_NAVIGATION_TOGGLE = 'toggle/PRIMARY_NAVIGATION_TOGGLE'

// RESERVATION PICKUP / DROPOFF
export const HYDRATE_RESERVATION = 'route/HYDRATE_RESERVATION'
export const HYDRATE_RESERVATION_UPDATED_DATA =
  'route/HYDRATE_RESERVATION_UPDATED_DATA'
export const RESERVATION_RETRIEVE_DATA = 'route/RESERVATION_RETRIEVE_DATA'
export const RESERVATION_INCREMENT_STEP = 'route/RESERVATION_INCREMENT_STEP'
export const RESERVATION_DECREMENT_STEP = 'route/RESERVATION_DECREMENT_STEP'
export const RESERVATION_PICKUP_SUBMIT = 'route/RESERVATION_PICKUP_SUBMIT'
export const RESERVATION_DROPOFF_SUBMIT = 'route/RESERVATION_DROPOFF_SUBMIT'
export const RESERVATION_LENDER_DROPOFF_SUBMIT =
  'route/RESERVATION_LENDER_DROPOFF_SUBMIT'
export const RESERVATION_SET_ERROR = 'route/RESERVATION_SET_ERROR'

// MESSAGING
export const ADDED_MESSAGE = 'messages/ADDED_MESSAGE'
export const REMOVED_MESSAGE = 'messages/REMOVED_MESSAGE'
// Assign GLOBAL_LOADER to any message that is represented by the global loading bar.
export const GLOBAL_LOADER = 'GLOBAL_LOADER'
export const GLOBAL_SPINNER = 'GLOBAL_SPINNER'
export const GLOBAL_API_LOADER = 'GLOBAL_API_LOADER'
// Assign SNACKBAR to any message as it's type to have the app-wide snackbar render.
export const SNACKBAR = 'SNACKBAR'
export const TOAST = 'TOAST'
// Assign LOGIN_MESSAGE to any message that should show up in the messaging area of the login view.
export const LOGIN_MESSAGE = 'LOGIN_MESSAGE'

// FIND
export const UPDATED_CURRENT_POSITION = 'find/map/UPDATED_CURRENT_POSITION'
export const MOVED_MARKER_INTO_VIEW = 'find/map/MOVED_MARKER_INTO_VIEW'
export const GET_CURRENT_POSITION = 'find/map/GET_CURRENT_POSITION'
export const UPDATED_CURRENT_ZOOM = 'find/map/UPDATED_CURRENT_ZOOM'
export const UPDATED_FLEET_LOCATIONS = 'find/shared/UPDATED_FLEET_LOCATIONS'
export const UPDATED_FILTERED_ASSET_COUNT =
  'find/shared/UPDATED_FILTERED_ASSET_COUNT'
export const UPDATED_ASSET_COUNT = 'find/shared/UPDATED_ASSET_COUNT'
export const SELECTED_FLEET_LOCATION = 'find/shared/SELECTED_FLEET_LOCATION'
// export const SELECT_FLEET_LOCATION_AND_RECENTER = 'find/shared/SELECT_FLEET_LOCATION_AND_RECENTER'
export const UPDATED_VEHICLES = 'find/shared/UPDATED_VEHICLES'
export const GET_VEHICLES = 'find/shared/GET_VEHICLES'
export const SET_FLEET_ASSETS_OPEN = 'find/shared/SET_FLEET_ASSETS_OPEN'
export const TOGGLED_FILTERS_OPEN = 'find/filters/TOGGLED_FILTERS_OPEN'
export const UPDATED_FILTERS = 'find/filters/UPDATED_FILTERS'
export const UPDATED_FILTERS_ADDRESS = 'find/filters/UPDATED_FILTERS_ADDRESS'
export const RESET_FILTERS = 'find/filters/RESET_FILTERS'
export const SET_SELECTED_PIN_LOCATION =
  'find/filters/SET_SELECTED_PIN_LOCATION'
// Booking
export const BOOKING_UPDATE_DATES = 'booking/BOOKING_UPDATE_DATES'

// Toggle ID for the Find a Vehicle list view.
export const FIND_LIST_TOGGLE = 'toggle/FIND_LIST_TOGGLE'

// Account
export const ACCOUNT_TOGGLE_FLEET_COVERAGE_INSURANCE =
  'account/ACCOUNT_TOGGLE_FLEET_COVERAGE_INSURANCE'
export const ACCOUNT_VEHICLE_SET_OUT_OF_STATE_TOGGLE =
  'account/ACCOUNT_VEHICLE_SET_OUT_OF_STATE_TOGGLE'
export const ACCOUNT_VEHICLE_SET_LOCATION = 'account/VEHICLE_SET_LOCATION'
export const SET_CAN_MODIFY_AVAILABILITY = 'account/SET_CAN_MODIFY_AVAILABILITY'
export const ENLIST_ASSET_START = 'account/ENLIST_ASSET_START'
export const ENLIST_ASSET_NEXT = 'account/ENLIST_ASSET_NEXT'
export const ENLIST_ASSET_BACK = 'account/ENLIST_ASSET_BACK'
export const ENLIST_ASSET_SUBMIT = 'account/ENLIST_ASSET_SUBMIT'
export const ENLIST_ASSET_EXIT = 'account/ENLIST_ASSET_EXIT'
export const SHOW_AVAILABILITY_ERROR_CANNOT_UNLIST =
  'account/SHOW_AVAILABILITY_ERROR_CANNOT_UNLIST'
export const SHOW_AVAILABILITY_ERROR_UNLIST_FIRST =
  'account/SHOW_AVAILABILITY_ERROR_UNLIST_FIRST'
export const SHOW_UNLIST_ERROR_CANNOT_UNLIST =
  'account/SHOW_UNLIST_ERROR_CANNOT_UNLIST'
export const SHOW_UNLIST_ERROR_UNLIST_FIRST =
  'account/SHOW_UNLIST_ERROR_UNLIST_FIRST'
export const SHOW_LENDER_INSURANCE_INFO = 'account/SHOW_LENDER_INSURANCE_INFO'
export const ADD_LOCATION = 'account/ADD_LOCATION'
export const EDIT_LOCATION = 'account/EDIT_LOCATION'
export const EDIT_LOCATION_UPDATED = 'account/EDIT_LOCATION_UPDATED'
export const PROFILE_UPDATE_PASSWORD = 'account/PROFILE_UPDATE_PASSWORD'
export const PROFILE_UPDATE_IMAGE = 'account/PROFILE_UPDATE_IMAGE'
export const PROFILE_STATE_UPDATE_IMAGE = 'account/PROFILE_STATE_UPDATE_IMAGE'
export const SHOW_COPIED_CONFIRMATION = 'account/SHOW_COPIED_CONFIRMATION'

// Fleet Images
export const FLEET_SET_IMAGES = 'fleet/FLEET_SET_IMAGES'
export const FLEET_UPDATE_BY_ID = 'fleet/FLEET_UPDATE_BY_ID'

// Payments
export const STORE_CREDIT_CARD = 'account/STORE_CREDIT_CARD'
export const AUTH_RESERVATION = 'reservations/AUTH_RESERVATION'
export const CHARGE_RESERVATION = 'reservations/CHARGE_RESERVATION'
export const RUN_TRANSFERS_FOR_RESERVATION =
  'reservations/RUN_TRANSFERS_FOR_RESERVATION'
export const PAYOUT_RESERVATION = 'reservations/PAYOUT_RESERVATION'

// rental date range business rules
export const MIN_PICKUP_HOURS_FROM_NOW = 0 // For Ryder onboarding, set this to 0. Normally, it will be 48
export const MIN_RENTAL_DAYS = 1
export const DATE_PICKER_VALUE_MOMENT_FORMAT = 'YYYY-MM-DD'
export const DATE_PICKER_FRIENDLY_FORMAT = 'MM DD YYYY'
export const DATE_PICKER_SHORT_FRIENDLY_FORMAT = 'MM-DD-YYYY'

export const SEARCH_RADIUS_MILES = 100
export const mapConfig = {
  atlantaCenter: {
    lat: 33.755511,
    lng: -84.391084,
  },
  defaultZoom: 9,
}

export const VehicleType = Object.freeze({
  Truck: 'truck',
  Tractor: 'tractor',
  Trailer: 'trailer',
})

export const VehicleBodyType = Object.freeze({
  Reefer: 'reefer',
  Flatbed: 'flatbed',
  Dry: 'dry',
  None: undefined,
})

export const VehicleSize = Object.freeze({
  Van: 'van',
  SmallTruck: 'smalltruck',
  LargeTruck: 'largetruck',
  SmallTrailer: 'smalltrailer',
  LargeTrailer: 'largetrailer',
  None: undefined,
})

export const VehicleAxleType = Object.freeze({
  Single: 'single',
  Tandem: 'tandem',
  TandemWithSleeper: 'tandemsleeper',
  None: undefined,
})

export const VehicleDoorType = Object.freeze({
  Roll: 'roll',
  Swing: 'swing',
  None: undefined,
})

export const SegmentedFilterState = Object.freeze({
  Yes: 'yes',
  No: 'no',
  Either: 'either',
})
export const segmentedFilterStates = [
  SegmentedFilterState.Yes,
  SegmentedFilterState.No,
  SegmentedFilterState.Either,
]

// FLEET
export const HYDRATED_FLEET = 'fleet/HYDRATED_FLEET'
export const CHECK_PENDING = 'fleet/CHECK_PENDING'
export const UNLISTED_VEHICLE = 'fleet/UNLISTED_VEHICLE'

// LOCATIONS
export const HYDRATED_LOCATIONS = 'locations/HYDRATED_LOCATIONS'

// PROFILE
export const HYDRATED_PROFILE = 'profile/HYDRATED_PROFILE'

// ADMIN
export const ROUTE_ADMIN_DASHBOARD = 'route/ADMIN_DASHBOARD'
export const ROUTE_ADMIN_CHATS = 'route/ADMIN_CHATS'
export const ROUTE_ADMIN_MULTI_VEHICLE_REQUEST =
  'route/ADMIN_MULTI_VEHICLE_REQUEST'
export const ROUTE_ADMIN_BULK_VEHICLE_EDITS =
  'route/ROUTE_ADMIN_BULK_VEHICLE_EDITS'
export const ROUTE_ADMIN_USERS = 'route/ADMIN_USERS'
export const ROUTE_ADMIN_VEHICLES = 'route/ADMIN_VEHICLES'
export const ROUTE_ADMIN_VEHICLES_BULK = 'route/ADMIN_VEHICLES_BULK'
export const ROUTE_ADMIN_COMPANIES = 'route/ROUTE_ADMIN_COMPANIES'
export const ROUTE_ADMIN_LOCATIONS = 'route/ROUTE_ADMIN_LOCATIONS'
export const ROUTE_ADMIN_PRICING_TIERS = 'route/ROUTE_ADMIN_PRICING_TIERS'
export const ROUTE_ADMIN_REVIEWS = 'route/ROUTE_ADMIN_REVIEWS'
export const ROUTE_ADMIN_DRIVERS = 'route/ROUTE_ADMIN_DRIVERS'
export const ROUTE_ADMIN_RESERVATIONS = 'route/ROUTE_ADMIN_RESERVATIONS'
export const ROUTE_ADMIN_RESERVATIONS_OPTIMIZED =
  'route/ROUTE_ADMIN_RESERVATIONS_OPTIMIZED'
export const ROUTE_ADMIN_BULK_RESERVATION_EDITS =
  'route/ROUTE_ADMIN_BULK_RESERVATION_EDITS'
export const ROUTE_ADMIN_SETTINGS = 'route/ROUTE_ADMIN_SETTINGS'
export const ROUTE_ADMIN_MARKETS = 'route/ROUTE_ADMIN_MARKETS'
export const ROUTE_ADMIN_PROMOTIONS = 'route/ROUTE_ADMIN_PROMOTIONS'
export const ROUTE_ADMIN_ZIPCODES = 'route/ROUTE_ADMIN_ZIPCODES'
export const ROUTE_ADMIN_DGRAPHS = 'route/ROUTE_ADMIN_DGRAPHS'
export const ROUTE_ADMIN_ANALYTICS = 'route/ROUTE_ADMIN_ANALYTICS'
export const ROUTE_ADMIN_FIND = 'route/ROUTE_ADMIN_FIND'
export const ROUTE_ADMIN_COMM_EVENTS = 'route/ROUTE_ADMIN_COMM_EVENTS'
export const ROUTE_ADMIN_MODEL_VALIDATIONS =
  'route/ROUTE_ADMIN_MODEL_VALIDATIONS'
export const ROUTE_ADMIN_RESERVATION_STATS =
  'route/ROUTE_ADMIN_RESERVATION_STATS'
export const ROUTE_ADMIN_COOP_API = 'route/ROUTE_ADMIN_COOP_API'
export const ROUTE_ADMIN_CACHE_REPORT = 'route/ROUTE_ADMIN_CACHE_REPORT'
export const ROUTE_ADMIN_DEV_SKETCHES = 'route/ROUTE_ADMIN_DEV_SKETCHES'
export const ROUTE_ADMIN_PENDING_ACCOUNTS = 'route/ROUTE_ADMIN_PENDING_ACCOUNTS'
export const ROUTE_ADMIN_PENDING_VEHICLES = 'route/ROUTE_ADMIN_PENDING_VEHICLES'

export const ADMIN_FETCH_ME = 'admin/ADMIN_FETCH_ME'
export const ADMIN_FETCH_ME_SUCCEEDED = 'admin/ADMIN_FETCH_ME_SUCCEEDED'

export const ADMIN_SET_MY_MARKET = 'admin/ADMIN_SET_MY_MARKET'
export const ADMIN_SET_MY_MARKET_SUCCEEDED =
  'admin/ADMIN_SET_MY_MARKET_SUCCEEDED'

export const ADMIN_FETCH_DOCS = 'admin/ADMIN_FETCH_DOCS'
export const ADMIN_FETCH_DOCS_SUCCEEDED = 'admin/ADMIN_FETCH_DOCS_SUCCEEDED'

export const ADMIN_FETCH_ANALYTICS = 'route/ADMIN_FETCH_ANALYTICS'
export const ADMIN_FETCH_ANALYTICS_SUCCEEDED =
  'admin/ADMIN_FETCH_ANALYTICS_SUCCEEDED'
export const ADMIN_FETCH_COMM_EVENTS = 'route/ADMIN_FETCH_COMM_EVENTS'
export const ADMIN_FETCH_MODEL_VALIDATIONS =
  'route/ADMIN_FETCH_MODEL_VALIDATIONS'

export const ADMIN_FETCH_DRIVERS = 'admin/ADMIN_FETCH_DRIVER'
export const ADMIN_CREATE_DRIVER = 'admin/ADMIN_CREATE_DRIVER'
export const ADMIN_UPDATE_DRIVER = 'admin/ADMIN_UPDATE_DRIVER'
export const ADMIN_DELETE_DRIVER = 'admin/ADMIN_DELETE_DRIVER'

export const ADMIN_FETCH_USERS = 'admin/ADMIN_FETCH_USERS'
export const ADMIN_CREATE_USER = 'admin/ADMIN_CREATE_USER'
export const ADMIN_UPDATE_USER = 'admin/ADMIN_UPDATE_USER'
export const ADMIN_DELETE_USER = 'admin/ADMIN_DELETE_USER'
export const ADMIN_FORGOT_PASSWORD = 'admin/ADMIN_FORGOT_PASSWORD'
export const ADMIN_CREATE_OKTA_ACCOUNT = 'admin/ADMIN_CREATE_OKTA_ACCOUNT'

// Email related types
export const ADMIN_CREATE_USER_AND_SEND_VERIFY_EMAIL =
  'admin/ADMIN_CREATE_USER_AND_SEND_VERIFY_EMAIL'
export const ADMIN_SEND_VERIFY_EMAIL = 'admin/ADMIN_SEND_VERIFY_EMAIL'

export const ADMIN_FETCH_VEHICLES = 'admin/ADMIN_FETCH_VEHICLES'
export const ADMIN_FETCH_VEHICLES_WITH_CAN_MODIFY_AVAILABILITY =
  'admin/ADMIN_FETCH_VEHICLES_WITH_CAN_MODIFY_AVAILABILITY'
export const ADMIN_CREATE_VEHICLE = 'admin/ADMIN_CREATE_VEHICLE'
export const ADMIN_UPDATE_VEHICLE = 'admin/ADMIN_UPDATE_VEHICLE'
export const ADMIN_DELETE_VEHICLE = 'admin/ADMIN_DELETE_VEHICLE'
export const ADMIN_CREATE_REFRESH_VEHICLE = 'admin/ADMIN_CREATE_REFRESH_VEHICLE'
export const ADMIN_APPROVE_VEHICLE = 'admin/ADMIN_APPROVE_VEHICLE'

export const ADMIN_FETCH_COMPANIES = 'admin/ADMIN_FETCH_COMPANIES'
export const ADMIN_CREATE_COMPANY = 'admin/ADMIN_CREATE_COMPANY'
export const ADMIN_UPDATE_COMPANY = 'admin/ADMIN_UPDATE_COMPANY'
export const ADMIN_UPDATE_COMPANY_DNR = 'admin/ADMIN_UPDATE_COMPANY_DNR'
export const ADMIN_DELETE_COMPANY = 'admin/ADMIN_DELETE_COMPANY'

export const ADMIN_FETCH_LOCATIONS = 'admin/ADMIN_FETCH_LOCATIONS'
export const ADMIN_CREATE_LOCATION = 'admin/ADMIN_CREATE_LOCATION'
export const ADMIN_UPDATE_LOCATION = 'admin/ADMIN_UPDATE_LOCATION'
export const ADMIN_DELETE_LOCATION = 'admin/ADMIN_DELETE_LOCATION'

export const ADMIN_FETCH_PRICING_TIERS = 'admin/ADMIN_FETCH_PRICING_TIERS'
export const ADMIN_CREATE_PRICING_TIER = 'admin/ADMIN_CREATE_PRICING_TIER'
export const ADMIN_UPDATE_PRICING_TIER = 'admin/ADMIN_UPDATE_PRICING_TIER'
export const ADMIN_DELETE_PRICING_TIER = 'admin/ADMIN_DELETE_PRICING_TIER'

export const ADMIN_FETCH_VEHICLE_PROPS = 'admin/ADMIN_FETCH_VEHICLE_PROPS'
export const ADMIN_CREATE_VEHICLE_PROPS = 'admin/ADMIN_CREATE_VEHICLE_PROPS'
// export const ADMIN_UPDATE_VEHICLE_PROPS = 'admin/ADMIN_UPDATE_VEHICLE_PROPS'
// export const ADMIN_DELETE_VEHICLE_PROPS = 'admin/ADMIN_DELETE_VEHICLE_PROPS'

export const ADMIN_FETCH_ZIPCODES = 'admin/ADMIN_FETCH_ZIPCODES'
export const ADMIN_FETCH_DGRAPHS = 'admin/ADMIN_FETCH_DGRAPHS'
export const ADMIN_UPDATE_DGRAPHS = 'admin/ADMIN_UPDATE_DGRAPHS'
export const ADMIN_CREATE_DGRAPHS = 'admin/ADMIN_CREATE_DGRAPHS'
export const ADMIN_DELETE_DGRAPHS = 'admin/ADMIN_DELETE_DGRAPHS'

export const ADMIN_FETCH_REVIEWS = 'admin/ADMIN_FETCH_REVIEWS'
export const ADMIN_CREATE_REVIEW = 'admin/ADMIN_CREATE_REVIEW'
export const ADMIN_UPDATE_REVIEW = 'admin/ADMIN_UPDATE_REVIEW'
export const ADMIN_DELETE_REVIEW = 'admin/ADMIN_DELETE_REVIEW'

export const ADMIN_FETCH_RESERVATIONS = 'admin/ADMIN_FETCH_RESERVATIONS'
export const ADMIN_CREATE_RESERVATION = 'admin/ADMIN_CREATE_RESERVATION'
export const ADMIN_UPDATE_RESERVATION = 'admin/ADMIN_UPDATE_RESERVATION'
export const ADMIN_DELETE_RESERVATION = 'admin/ADMIN_DELETE_RESERVATION'

export const ADMIN_FETCH_SETTINGS = 'admin/ADMIN_FETCH_SETTINGS'
export const ADMIN_FETCH_SETTINGS_SUCCEEDED =
  'admin/ADMIN_FETCH_SETTINGS_SUCCEEDED'
export const ADMIN_UPDATE_SETTINGS = 'admin/ADMIN_UPDATE_SETTINGS'

export const ADMIN_FETCH_MARKETS = 'admin/ADMIN_FETCH_MARKETS'
export const ADMIN_CREATE_MARKET = 'admin/ADMIN_CREATE_MARKET'
export const ADMIN_UPDATE_MARKET = 'admin/ADMIN_UPDATE_MARKET'
export const ADMIN_DELETE_MARKET = 'admin/ADMIN_DELETE_MARKET'

export const ADMIN_FETCH_PROMOTIONS = 'admin/ADMIN_FETCH_PROMOTIONS'
export const ADMIN_CREATE_PROMOTION = 'admin/ADMIN_CREATE_PROMOTION'
export const ADMIN_UPDATE_PROMOTION = 'admin/ADMIN_UPDATE_PROMOTION'
export const ADMIN_DELETE_PROMOTION = 'admin/ADMIN_DELETE_PROMOTION'

export const ADMIN_CACHE_REQUEST_MODEL = 'admin/ADMIN_CACHE_REQUEST_MODEL'
export const ADMIN_REFRESH_REQUEST_MODEL = 'admin/ADMIN_REFRESH_REQUEST_MODEL'
export const ADMIN_SET_REFRESH_FN = 'admin/ADMIN_SET_REFRESH_FN'
export const ADMIN_SET_REQUEST_META = 'admin/ADMIN_SET_REQUEST_META'
export const ADMIN_SET_REQUEST_META_AND_REFRESH =
  'admin/ADMIN_SET_REQUEST_META_AND_REFRESH'
export const ADMIN_REFRESH_SUCCEEDED = 'admin/ADMIN_REFRESH_SUCCEEDED'
export const ADMIN_API_CALL_FAILED = 'admin/ADMIN_API_CALL_FAILED'
export const ADMIN_DISMISS_ERRORS = 'admin/ADMIN_DISMISS_ERRORS'
export const ADMIN_PUSH_IS_BUSY = 'admin/ADMIN_PUSH_IS_BUSY'
export const ADMIN_POP_IS_BUSY = 'admin/ADMIN_POP_IS_BUSY'
export const ADMIN_CLEAR_IS_BUSY = 'admin/ADMIN_CLEAR_IS_BUSY'

export const ADMIN_PUSH_BREADCRUMBS = 'admin/ADMIN_PUSH_BREADCRUMBS'
export const ADMIN_POP_BREADCRUMBS = 'admin/ADMIN_POP_BREADCRUMBS'
export const ADMIN_SET_BREADCRUMBS = 'admin/ADMIN_SET_BREADCRUMBS'

export const ADMIN_UPDATE_DISPLAY_OPTIONS = 'admin/ADMIN_UPDATE_DISPLAY_OPTIONS'

export const ADMIN_FETCH_PENDING_ACCOUNTS = 'admin/ADMIN_FETCH_PENDING_ACCOUNTS'
export const ADMIN_CREATE_PENDING_ACCOUNT = 'admin/ADMIN_CREATE_PENDING_ACCOUNT'
export const ADMIN_UPDATE_PENDING_ACCOUNT = 'admin/ADMIN_UPDATE_PENDING_ACCOUNT'
export const ADMIN_DELETE_PENDING_ACCOUNT = 'admin/ADMIN_DELETE_PENDING_ACCOUNT'
export const ADMIN_VERIFY_PENDING_ACCOUNT = 'admin/ADMIN_VERIFY_PENDING_ACCOUNT'

// BOOKING
export const SET_BOOKING_COST_ESTIMATE = 'booking/SET_BOOKING_COST_ESTIMATE'
export const SET_BOOKING_UNAVAILABILITY = 'booking/SET_BOOKING_UNAVAILABILITY'
export const SUBMIT_BOOKING = 'booking/SUBMIT_BOOKING'
export const SUBMIT_BOOKING_ERROR = 'booking/SUBMIT_BOOKING_ERROR'

// TOGGLES
export const ADDED_TOGGLE = 'toggles/ADDED_TOGGLE'
export const REMOVED_TOGGLE = 'toggles/REMOVED_TOGGLE'
export const ADDED_EXCLUSIVE_TOGGLE = 'toggles/ADDED_EXCLUSIVE_TOGGLE'

// BORROWING
export const HYDRATED_BORROWING = 'borrowing/HYDRATED_BORROWING'
export const CHANGED_BORROWING_DRIVER = 'borrowing/CHANGED_BORROWING_DRIVER'
export const CHANGED_BORROWING_DETAILS_DRIVER =
  'borrowing/CHANGED_BORROWING_DETAILS_DRIVER'
export const CHANGED_LENDER_RATING = 'borrowing/CHANGED_LENDER_RATING'
export const SET_BORROWING_DETAILS = 'borrowing/SET_BORROWING_DETAILS'
export const ADDED_LENDER_OWNER = 'borrowing/ADDED_LENDER_OWNER'
export const CANCEL_RESERVATION = 'borrowing/CANCEL_RESERVATION'
export const CANCEL_EXTENSION_REQUEST = 'borrowing/CANCEL_EXTENSION_REQUEST'
export const REQUEST_BORROWING_EXTENSION =
  'borrowing/REQUEST_BORROWING_EXTENSION'
export const REQUEST_BORROWING_DETAILS_EXTENSION =
  'borrowing/REQUEST_BORROWING_DETAILS_EXTENSION'
export const REQUEST_LENDING_EXTENSION = 'borrowing/REQUEST_LENDING_EXTENSION'
export const REQUEST_LENDING_DETAILS_EXTENSION =
  'lending/REQUEST_LENDING_DETAILS_EXTENSION'

// RESERVATIONS
export const HYDRATED_RESERVATION_DETAILS =
  'borrowing/HYDRATED_RESERVATION_DETAILS'
export const RESET_RESERVATION_DETAILS = 'borrowing/RESET_RESERVATION_DETAILS'
export const HYDRATED_RESERVATION_TIMELINE =
  'borrowing/HYDRATED_RESERVATION_TIMELINE'
export const RESERVATION_REVIEW_REQUEST =
  'reservations/RESERVATION_REVIEW_REQUEST'
export const SUBMIT_DISPUTE_REQUEST = 'reservations/SUBMIT_DISPUTE_REQUEST'

// LENDING
export const HYDRATED_LENDING = 'lending/HYDRATED_LENDING'
export const HYDRATE_LENDING_BY_ID = 'lending/HYDRATE_LENDING_BY_ID'
export const SET_LENDING_DETAILS = 'lending/SET_LENDING_DETAILS'
export const ADDED_BORROWER_OWNER = 'lending/ADDED_BORROWER_OWNER'
export const APPROVE_RESERVATION = 'lending/APPROVE_RESERVATION'
export const DECLINE_RESERVATION = 'lending/DECLINE_RESERVATION'
export const APPROVE_LENDING_RESERVATION = 'lending/APPROVE_LENDING_RESERVATION'
export const DECLINE_LENDING_RESERVATION = 'lending/DECLINE_LENDING_RESERVATION'

// BASE
export const HYDRATED_BASE = 'base/HYDRATED_BASE'
export const UPDATE_STRIPE_BORROWER = 'base/UPDATE_STRIPE_BORROWER'
export const UPDATE_STRIPE_LENDER = 'base/UPDATE_STRIPE_LENDER'
export const LENDER_SET_DASHBOARD_LINK = 'base/LENDER_SET_DASHBOARD_LINK'
export const UPDATE_LENDER_HAS_PENDING = 'base/UPDATE_LENDER_HAS_PENDING'
export const UPDATE_BORROWER_HAS_PENDING = 'base/UPDATE_BORROWER_HAS_PENDING'
export const BASE_READY = 'base/BASE_READY'
export const UPDATE_COOP_INSURANCE = 'base/UPDATE_COOP_INSURANCE'

// DRIVERS
export const ADDED_DRIVER = 'drivers/ADDED_DRIVER'
export const HYDRATED_DRIVERS = 'drivers/HYDRATED_DRIVERS'
export const UPDATED_DRIVER = 'drivers/UPDATED_DRIVERS'

// HELP
export const SUBMIT_CONTACT_FORM = 'help/SUBMIT_CONTACT_FORM'

export const GET_GEOLOCATION = 'location/GET_GEOLOCATION'

// GLOBAL/RESERVATIONS
export const SEND_RESERVATION_CONTRACT =
  'global/reservations/SEND_RESERVATION_CONTRACT'
export const SEND_DRIVER_RES_SMS = 'global/reservations/SEND_DRIVER_RES_SMS'
export const SET_SERVER_DB_VERSION_SUPPORT =
  'global/SET_SERVER_DB_VERSION_SUPPORT'

// COMPONENT LIBRARY
export const ROUTE_COMPONENT_LIBRARY = 'global/ROUTE_COMPONENT_LIBRARY'

// RESERVATIONS OPTIMIZED
export const ADMIN_FETCH_COMPANIES_NAMES =
  'reservations-optimized/ADMIN_FETCH_COMPANIES_NAMES'
export const ADMIN_FETCH_COMPANIES_NAMES_SUCCEEDED =
  'reservations-optimized/ADMIN_FETCH_COMPANIES_NAMES_SUCCEEDED'
export const ADMIN_FETCH_RESERVATIONS_OPTIMIZED =
  'reservations-optimized/ADMIN_FETCH_RESERVATIONS_OPTIMIZED'
export const ADMIN_FETCHING_RESERVATIONS_OPTIMIZED =
  'reservations-optimized/ADMIN_FETCHING_RESERVATIONS_OPTIMIZED'
export const ADMIN_FETCH_RESERVATIONS_OPTIMIZED_SUCCEEDED =
  'reservations-optimized/ADMIN_FETCH_RESERVATIONS_OPTIMIZED_SUCCEEDED'
export const ADMIN_FETCHING_RESERVATIONS_OPTIMIZED_RESET =
  'reservations-optimized/ADMIN_FETCHING_RESERVATIONS_OPTIMIZED_RESET'
export const ADMIN_SET_SORT_OPTIONS =
  'reservations-optimized/ADMIN_SET_SORT_OPTIONS'
export const ADMIN_SET_PAGINATION =
  'reservations-optimized/ADMIN_SET_PAGINATION'
export const ADMIN_SET_FILTERS = 'reservations-optimized/ADMIN_SET_FILTERS'
export const ADMIN_LOCALLY_DELETE_RESERVATION =
  'reservations-optimized/ADMIN_LOCALLY_DELETE_RESERVATION'
