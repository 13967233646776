
import {combineReducers} from 'redux'

import {PERFORM_LOGOUT} from 'types'

import {reducer as location} from 'state/routes'
import {reducer as messages} from 'state/reducers/messages'
import {reducer as admin} from 'state/reducers/admin'
import {reducer as toggles} from 'state/reducers/toggles'
import {reducer as base} from 'state/reducers/base'
import {reducer as server} from 'state/reducers/server'

const appReducers = combineReducers({
  location,
  messages,
  admin,
  toggles,
  base,
  server
})

// If the action coming in is a logout we pass undefined, which causes Redux to effectively
// initialize reducers with initialState. Otherwise continue on to our normal application
// reducers.
export const reducers = (state, action) =>
  appReducers(action.type === PERFORM_LOGOUT ? undefined : state, action)
