import React from 'react'
import { up } from 'styled-breakpoints'
import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  FlattenSimpleInterpolation,
  ThemeProps,
} from 'styled-components'
import { ILinkProps, StyledLinkAttributes } from './types'

const linkColors = (): {
  [key in StyledLinkAttributes['color']]: FlattenSimpleInterpolation
} => ({
  dark: css`
    color: #38474f;
  `,
  light: css`
    color: #f8f9fa;
  `,
  inherit: css``,
})

// TODO - Add styles for all sizes
const linkSizes: {
  [key in StyledLinkAttributes['size']]:
    | FlattenSimpleInterpolation
    | FlattenInterpolation<ThemeProps<DefaultTheme>>
} = {
  sm: css``,
  md: css``,
  lg: css`
    min-width: 16.25rem;
  `,
  auto: css`
    width: 100%;
    ${up('sm')} {
      max-width: 336px;
    }
  `,
}

/* TODO - Add styles for all variants */
const linkVariants = (): {
  [key in StyledLinkAttributes['variant']]: FlattenSimpleInterpolation
} => ({
  'button-secondary': css`
    background-color: rgba(248, 87, 49, 1);
    border: 1px solid rgba(255, 255, 255, 1);
    border-radius: 8px;
    padding: 14px;
    color: rgba(255, 255, 255, 1);
    font: normal 600 16px/20px Poppins;
  `,
  'button-primary': css`
    background-color: rgba(65, 80, 119, 1);
    border: 1px solid rgba(255, 255, 255, 1);
    border-radius: 8px;
    padding: 14px;
    color: rgba(255, 255, 255, 1);
    font: normal normal 600 16px/20px Poppins;
  `,
  default: css`
    font-size: 14px;
    font-weight: 400;
    font-family: #415077;
    color: normal normal 400 16px/24px Poppins;
    align-self: center;
  `,
  'small-link': css`
    font-size: 12px;
    font-weight: 500;
    font-family: #415077;
    color: normal normal 400 16px/24px Poppins;
    align-self: center;
  `,
})

const StyledLink = styled.a<StyledLinkAttributes>`
  text-decoration: ${(props) => (props.underline ? 'underline' : 'none')};
  text-align: center;
  ${({ size }) => linkSizes[size]}
  ${({ variant }) => linkVariants()[variant]}
  ${({ color }) => linkColors()[color]}
`

/**
 * All different kind of links with its variants,
 * see ILinkProps for details.
 */
const Link = ({
  children,
  color = 'inherit',
  href,
  size = 'md',
  style,
  target = '_self',
  underline,
  variant = 'default',
  ...rest
}: ILinkProps) => (
  <StyledLink
    color={color}
    size={size}
    style={style}
    target={target}
    href={href}
    underline={underline}
    variant={variant}
    {...rest}
  >
    {children}
  </StyledLink>
)

export default Link
