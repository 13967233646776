import React, { useState, useEffect, Dispatch } from 'react'
import { connect, MapDispatchToPropsParam } from 'react-redux'
import styled from 'styled-components'

import { removeMessage } from 'actions'
import { SNACKBAR, TOAST } from 'types'

import { toast } from 'react-toastify'
import { messageShape } from 'coopPropTypes'
import Link from 'components/Link'

interface ActionLinkProps {
  linkDescription?: string;
  linkUrl?: string
}

const ActionLink = ({ linkDescription, linkUrl }: ActionLinkProps) => {
  return (
    <Link
      href={linkUrl!}
      underline={true}
      rel='noopener noreferrer'
      style={{ color: '#FFF', marginRight: '24px' }}
    >
      {linkDescription}
    </Link>
  )
}

const ToastSpan = styled.span`
  display: inline-block;
  margin-top: 10px;
  background-color: transparent;
  border: 0;
  color: #fff;
  cursor: pointer;
  text-decoration: underline;
`

const MessageText = styled.p<{ notificationType?: string }>`
  color: ${({ theme, notificationType }) =>
    notificationType === 'warn'
      ? theme.palette.neutrals.darkest.toHex()
      : theme.palette.neutrals.lightest.toHex()};
`

const mapStateToProps = (state: any) => ({
  message: state.messages.find((m: any) => [SNACKBAR, TOAST].includes(m.type)) as Message | undefined,
})

const mapDispatchToProps = (dispatch: any) => ({
  removeMessage: (id: string) => dispatch(removeMessage(id)),
})

interface Message {
  type?: string | null;
  id?: string | null;
  text?: string | null;
  linkUrl?: string | (() => void) | null;
  linkDescription?: string | null;
} 

interface TostifyContainerProps {
  message?: Message;
  removeMessage: (id?: string | null) => void;
}

const SnackBarContainer = ({ message, removeMessage }: TostifyContainerProps) => {
  const handleClose = () => {
    setTimeout(() => {
      removeMessage(message?.id)
    }, 500)
  }

  const notificationOptions = {
    toastId: 'snack-container',
    onClose: handleClose,
    autoClose: 6000,
  }

  useEffect(() => {
    if (message) {
      toast(
        <div>
          {message.text && <MessageText>{message.text}</MessageText>}
          {message && message.linkUrl && (
            <ActionLink
              linkDescription={message.linkDescription!}
              linkUrl={message.linkUrl as string}
            />
          )}
        </div>,
        notificationOptions
      )
    }
  }, [message])

  return <></>
}

const ToastContainer = ({ message, removeMessage }: TostifyContainerProps) => {
  const handleClose = () => {
    setTimeout(() => {
      removeMessage(message?.id)
    }, 500)
  }

  const notificationOptions = {
    toastId: 'toast-container',
    onClose: handleClose,
  }

  useEffect(() => {
    if (message) {
      toast(
        <div>
          {message.text && <MessageText>{message.text}</MessageText>}
          {message.linkDescription && (
            <ToastSpan
              onClick={() => {
                if (!message.linkUrl) return

                if (typeof message.linkUrl === 'string') {
                  window.open(message.linkUrl, '_blank')
                } else if (typeof message.linkUrl === 'function') {
                  message.linkUrl()
                }

                handleClose()
              }}
            >
              {message.linkDescription}
            </ToastSpan>
          )}
        </div>,
        notificationOptions
      )
    }
  }, [message])

  return <></>
}

interface ContainerProps {
  message?: Message;
  removeMessage: (id?: string | null) => void;
}

const Container = (props: ContainerProps) => {
  if (props.message && props.message.type === TOAST) {
    return <ToastContainer {...props} />
  } else {
    return <SnackBarContainer {...props} />
  }

  return <></>
}

Container.propTypes = {
  message: messageShape,
}

// @ts-ignore
export const Messages = connect(mapStateToProps, mapDispatchToProps)(Container)