
import {fork, takeLatest} from 'redux-saga/effects'
import {AdminCrudSagas} from './shared'
import {
  adminFetchDrivers
} from 'actions'
import {
  ADMIN_FETCH_DRIVERS,
  ADMIN_CREATE_DRIVER,
  ADMIN_UPDATE_DRIVER,
  ADMIN_DELETE_DRIVER
} from 'types'

class DriversSagas extends AdminCrudSagas {
}
const sagas = new DriversSagas({
  modelNames: { singular: 'driver', plural: 'drivers' },
  endpoint: 'crud/drivers',
  fetchAction: adminFetchDrivers,
  updateMethod: 'patch',
  apiVersion: 'v2'
})

export function * init () {
  yield fork(function * () { yield takeLatest(ADMIN_FETCH_DRIVERS, sagas.fetch.bind(sagas)) })
  yield fork(function * () { yield takeLatest(ADMIN_CREATE_DRIVER, sagas.create.bind(sagas)) })
  yield fork(function * () { yield takeLatest(ADMIN_UPDATE_DRIVER, sagas.update.bind(sagas)) })
  yield fork(function * () { yield takeLatest(ADMIN_DELETE_DRIVER, sagas.delete.bind(sagas)) })
}

export const routeTrigger = sagas.routeTrigger.bind(sagas)
