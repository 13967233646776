import { select, put, fork, takeLatest } from 'redux-saga/effects'
import { 
  ADMIN_REFRESH_REQUEST_MODEL, 
  ADMIN_UPDATE_DISPLAY_OPTIONS, 
  ADMIN_SET_REQUEST_META_AND_REFRESH
} from 'types'
import { adminApiCallFailed, adminRefreshSucceeded, adminSetRequestMeta } from 'actions'
import { AdminError } from 'state/reducers/admin/errors'

function * refreshRequestModel () {
  const request = yield select(state => { return state.admin.request })
  if (request.refreshFn) {
    try {
      yield request.refreshFn()
      yield put(adminRefreshSucceeded({ requestId: request.requestId }))
    } catch (error) {
      yield put(adminApiCallFailed(new AdminError('Refreshing Model', error)))
    }
  }
}

function * setRequestMetaAndRefresh (action) {
  yield put(adminSetRequestMeta(action.payload))
  yield refreshRequestModel()
}

// trigger a refresh automatically
function * handleUpdateDisplayOptions () {
  yield refreshRequestModel()
}

export function * init () {
  yield fork(function * () { yield takeLatest(ADMIN_REFRESH_REQUEST_MODEL, refreshRequestModel) })
  yield fork(function * () { yield takeLatest(ADMIN_UPDATE_DISPLAY_OPTIONS, handleUpdateDisplayOptions) })
  yield fork(function * () { yield takeLatest(ADMIN_SET_REQUEST_META_AND_REFRESH, setRequestMetaAndRefresh) })
}
