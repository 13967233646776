import {fork, takeLatest, call, put, select} from 'redux-saga/effects'
import {AdminCrudSagas, next} from './shared'
import api from 'state/utils/api'
import {AdminError} from 'state/reducers/admin/errors'
import {
  adminFetchAnalytics,
  adminFetchAnalyticsSucceeded,
  adminApiCallFailed
} from 'actions'
import {
  ADMIN_FETCH_ANALYTICS
} from 'types'

class AnalyticsSagas extends AdminCrudSagas {
  * fetch (action) {
    let response

    const analytics = yield select(state => state.admin.analytics)
    const begin = action.payload.startDate !== undefined ? action.payload.startDate.format('YYYY-MM-DD') : analytics.begin
    const end = action.payload.endDate !== undefined ? action.payload.endDate.format('YYYY-MM-DD') : analytics.end
    const params = {
      begin,
      end
    }

    try {
      response = yield call([api, 'get'], `/analytics`, params)
      let successPayload = { response }

      successPayload.response.begin = begin
      successPayload.response.end = end
      successPayload.collectionName = 'analytics'

      if (action.payload) {
        // pass on the request id if there was one
        successPayload.requestId = action.payload.requestId
      } else {
        console.log('Not action.payload')
      }

      yield put(this.fetchSuccessAction(successPayload))
      yield next(action)
    } catch (error) {
      yield put(adminApiCallFailed(new AdminError(`Fetch Analytics`, error)))
    }
  }
}
const sagas = new AnalyticsSagas({
  modelNames: { singular: 'analytics', plural: 'analytics' },
  fetchAction: adminFetchAnalytics,
  fetchSuccessAction: adminFetchAnalyticsSucceeded
})

export function * init () {
  yield fork(function * () { yield takeLatest(ADMIN_FETCH_ANALYTICS, sagas.fetch.bind(sagas)) })
}

export const routeTrigger = sagas.routeTrigger.bind(sagas)
