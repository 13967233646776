
import {fork, takeLatest} from 'redux-saga/effects'
import {NoTableAdminCrudSagas} from './shared'
import {
  adminFetchZipcodes,
  adminFetchPricingTiers
} from 'actions'
import {
  ADMIN_FETCH_ZIPCODES
} from 'types'

class ZipcodeSagas extends NoTableAdminCrudSagas {
	* fetch (action) {
    // no-op ... fetching 41k zips is bad
  }

  * customRouterTrigger () {
    yield this.routeTrigger({ type: ADMIN_FETCH_ZIPCODES })
  }
}

const sagas = new ZipcodeSagas({
  modelNames: { singular: 'zipcode', plural: 'zipcodes' },
  endpoint: 'crud/zipcodes',
  fetchAction: adminFetchZipcodes,
  preFetchActions: [adminFetchPricingTiers],
  updateMethod: 'patch',
  apiVersion: 'v2'
})

export function * init () {
  yield fork(function * () { yield takeLatest(ADMIN_FETCH_ZIPCODES, sagas.fetch.bind(sagas)) })
}

export const routeTrigger = sagas.customRouterTrigger.bind(sagas)
