// rules for the react-a11y plug-in, called in index.js

export default {
  'button-role-space': 'warn',
  'hidden-uses-tabindex': 'warn',
  'img-uses-alt': 'warn',
  'label-uses-for': 'warn',
  'mouse-events-map-to-key-events': 'warn',
  'no-hash-ref': 'warn',
  'onclick-uses-role': 'warn',
  'onclick-uses-tabindex': 'warn',
  'redundant-alt': [
    'warn',
    [
      'image',
      'photo',
      'picture'
    ]
  ],
  'tabindex-uses-button': 'warn',
  'use-onblur-not-onchange': 'warn'
}
