import { css } from 'styled-components'
import grey from 'material-ui/colors/grey'
import red from 'material-ui/colors/red'
import deepOrange from 'material-ui/colors/deepOrange'
import { createMuiTheme } from 'material-ui/styles'
import { createTheme } from '@material-ui/core/styles'

import 'typeface-roboto'
import 'typeface-rubik'
import 'typeface-poppins'

// THEMES
// We are leveraging both styled-components theming and material-ui. Material gives us our basic
// color palette and such.

// COLORS
const [primary, primaryLight, secondary, error, warning] = [
  deepOrange[400],
  deepOrange[300],
  '#415077',
  red[900],
  '#ffd902',
]

const grays = {
  lightest: '#f6f6f6',
  lighter: '#e4e7e8',
  light: '#bdbdbd',
  medium: '#62727b',
  dark: '#37474f',
  darker: '#14284b',
  darkest: '#20272b',
}

const materialThemeJSON = {
  palette: {
    primary: {
      main: '#ff7043',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#415077',
    },
    error: red,
  },
  typography: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: 16,
    headline: {
      fontFamily: 'Rubik, Helvetica, Arial, sans-serif',
      fontWeight: '400',
      textTransform: 'uppercase',
      fontSize: '1.25rem',
    },
    title: {
      fontFamily: 'Rubik, Helvetica, Arial, sans-serif',
    },
    subheading: {
      fontFamily: 'Rubik, Helvetica, Arial, sans-serif',
    },
    body1: {
      color: '#37474f',
    },
  },
  overrides: {
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: '#ff7043',
        },
      },
    },
  },
}

// MATERIAL-UI THEME
export const materialTheme = createMuiTheme(materialThemeJSON)
export const coreMaterialTheme = createTheme(materialThemeJSON)

// STYLED-COMPONENTS THEME
export const scTheme = {
  primary,
  primaryLight,
  secondary,
  error,
  warning,
  white: grey[50],
  black: '#102027',
  grays,
  space: '1.5rem',
  halfSpace: '.75rem',
  quarterSpace: '.375rem',
  eighthSpace: '.1875rem',
  doubleSpace: '3rem',
  headingFont: 'Rubik, Helvetica, Arial, sans-serif',
  borders: {
    hairlineLight: `1px solid ${grays.light}`,
    hairlineMedium: `1px solid ${grays.medium}`,
    thinLight: `2px solid ${grays.light}`,
    thinMedium: `2px solid ${grays.medium}`,
    thinPrimaryLight: `1px solid ${deepOrange[300]}`,
  },
}

// Simple helper function, takes in any number of props mapping to properties within the theme
// object and returns the value.
export const getTheme =
  (...props) =>
  ({ theme }) =>
    props.reduce((t, p) => t[p], theme)

export const breakpoints = {
  smallUp: 600,
  mediumUp: 960,
  lg: 1280,
}

export const currentBreakpointName = () => {
  if (window.innerWidth <= breakpoints.smallUp) {
    return 'smallUp'
  } else if (window.innerWidth <= breakpoints.mediumUp) {
    return 'mediumUp'
  }
  return 'lg'
}

// MEDIA QUERIES
export const media = {
  smallUp: (...args) => css`
    @media (min-width: ${breakpoints.smallUp}px) {
      ${css(...args)}
    }
  `,
  mediumUp: (...args) => css`
    @media (min-width: ${breakpoints.mediumUp}px) {
      ${css(...args)}
    }
  `,
  lg: (...args) => css`
    @media (min-width: ${breakpoints.lg}px) {
      ${css(...args)}
    }
  `,
}
