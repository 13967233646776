import { call, spawn, put } from 'redux-saga/effects'
import { addMessage, removeMessage, removeToggle } from 'actions'
import { GLOBAL_LOADER, PRIMARY_NAVIGATION_TOGGLE } from 'types'

import { ownerAuthFilter, handleAuthFailed } from './authentication'

/**
 * We have a bunch of side-effect stuff tied to routes.
 * Some of these are fine, but some should probably be
 * centralized (@see ./global/index.js for a more thorough
 * diatribe).
 *
 * There are two things most route-tied sagas want to do:

 *   1. listen for relevant actions
 *   2. do something every time the route is hit
 *
 * Those two things had been conflated before, and new
 * listeners (`takeEvery`, `takeLatest`) were being spawned
 * *every time the route was hit*. @see https://cpbgroup.atlassian.net/browse/COOPFE-407
 *
 * This super-class does a few things:
 *
 *   1. template for route-tied sagas to do their two things
 *      separately from one another
 *   2. spot for route-tied sagas to define their level of auth
 *   3. choke point for things that should happen on route changes
 *
 * Routes should subclass this and override:
 *
 *   - `initListeners`, for things that should only happen once
 *   - `handleRouteHit`, for things that should happen every time
 *     a route is hit
 *   - `authFilter`, if an auth level other than `owner` is required
 *
 */
let exportClass = class CoopRouteSagas {
  constructor() {
    // most uses will want owner-level auth
    this.authFilter = ownerAuthFilter
    this.visited = false
  }

  static *handleRouteChanged(routeAction) {
    const newRouteType = routeAction.type
    const newRoute = CoopRouteSagas.appRouteSagas[newRouteType]
    const isAuthenticated = yield call(newRoute.authFilter)
    yield put(removeToggle(PRIMARY_NAVIGATION_TOGGLE))
    if (newRoute) {
      if (!isAuthenticated) {
        const { pathname } = window.location
        yield handleAuthFailed(pathname)
      } else {
        if (!newRoute.visited) {
          yield spawn(newRoute.initListeners)
          newRoute.visited = true
        }

        // do any app-wide pre/post route stuff
        yield put(addMessage(GLOBAL_LOADER))
        yield newRoute.handleRouteHit()
        newRoute.decorateBody(newRoute)
        yield put(removeMessage(GLOBAL_LOADER))
      }
    }
  }

  /**
   * Optionally decorate the body tag with a custom class
   * name identifying this route.
   *
   * Not very react-y but use case is to manage placement of olark
   * chat button, which is outside of react anyway.
   *
   * @param {*} route
   */
  decorateBody(route) {
    try {
      if (document.body.classList.value) {
        let prevRouteTokens = document.body.classList.value
          .split(' ')
          .filter((t) => t.indexOf('coop-route-') !== -1)

        for (let t of prevRouteTokens) {
          document.body.classList.remove(t)
        }
      }
      const customRouteClassName = route.getRouteBodyClassName()

      if (customRouteClassName && customRouteClassName.length) {
        document.body.classList.add(`coop-route-${customRouteClassName}`)
      }
    } catch (e) {}
  }

  // subclasses override if needed
  getRouteBodyClassName() {
    return null
  }

  // subclasses override
  *initListeners() {}

  // subclasses override
  *handleRouteHit() {}
}

//
exportClass.appRouteSagas = {}

export default exportClass
