import { call, put, select, all } from 'redux-saga/effects'
import api from 'state/utils/api-v2'
import apiV2 from 'state/utils/api-v2'
import {
  adminApiCallFailed,
  adminSetBreadcrumbs,
  adminSetRefreshFn,
  adminSetRequestMeta,
  adminFetchDocsSucceeded,
  adminPushIsBusy,
  adminPopIsBusy,
} from 'actions'
import { AdminError } from 'state/reducers/admin/errors'
import { Breadcrumb } from 'state/reducers/admin/breadcrumbs'
import { capitalize, trimDoubleSlash } from '../../../utils'

export function* next(action) {
  if (action.payload && action.payload.next) {
    yield action.payload.next()
  }
}

export function* buildFetchQuery(fetchQuery) {
  let shouldShowDeleted = yield select((state) => {
    return state.admin.request.displayOptions.showDeleted
  })
  let shouldShowArchived = yield select((state) => {
    return state.admin.request.displayOptions.showArchived
  })
  let marketFilter = yield select((state) =>
    state.admin.me.market ? state.admin.me.market : null
  )

  const request = yield select((state) => {
    return state.admin.request
  })

  // mmm, bit weird, but if the main request is for a single item, always pull archived & deleted as well.
  // without this, setting a model to archived and then saving it makes it impossible to view without the
  // global setting. also assuming that we'll want to be able to see far ends of relationships to
  // deleted/archived items. TODO: revisit this; it's not quite right.
  if (request.isSingle) {
    shouldShowArchived = shouldShowDeleted = true
  }

  const updateFetchQueryMeta = (propName, value) => {
    fetchQuery = fetchQuery || {}
    fetchQuery.meta = fetchQuery.meta || {}
    fetchQuery.meta[propName] = value
  }

  if (shouldShowDeleted) {
    updateFetchQueryMeta('includeDeleted', true)
  }

  if (shouldShowArchived) {
    updateFetchQueryMeta('includeArchived', true)
  }

  if (marketFilter && !request.isSingle) {
    updateFetchQueryMeta('filterByMarket', marketFilter)
  }

  if (request.meta.sort) {
    updateFetchQueryMeta('sort', request.meta.sort)
  }
  if (request.meta.limit) {
    updateFetchQueryMeta('limit', request.meta.limit)
  }
  if (request.meta.skip) {
    updateFetchQueryMeta('skip', request.meta.skip)
  }

  if (fetchQuery) {
    fetchQuery.meta = fetchQuery.meta
      ? JSON.stringify(fetchQuery.meta)
      : fetchQuery.meta
  }

  return fetchQuery
}

export class AdminCrudSagas {
  /**
   * TODO: document usage.
   */
  constructor({
    modelNames,
    endpoint,
    updateMethod,
    fetchAction,
    fetchQuery,
    preFetchActions,
    fetchSuccessAction,
    apiVersion,
  }) {
    this.modelNames = modelNames
    this.endpoint = endpoint
    this.fetchAction = fetchAction
    this.fetchQuery = fetchQuery
    this.preFetchActions = preFetchActions || []
    this.fetchSuccessAction = fetchSuccessAction || adminFetchDocsSucceeded
    this.updateMethod = updateMethod || 'put'
    this.api = !apiVersion || apiVersion.indexOf('2') === -1 ? api : apiV2
  }

  *fetch(action) {
    const { payload, populate } = action
    let response

    let url = `/${this.endpoint}/`
    try {
      const shouldFetch = this.getShouldFetch(action)

      if (payload && payload.assoc && payload.modelId) {
        //  where are we using this?
        //  console.log('🤔')
        // i believe we aren't ... the "assoc" stuff has never been used - EM 2020/02/04
        url = `/${this.endpoint}/${payload.assoc}/${payload.modelId}`
      } else if (payload && payload.modelId) {
        url = `/${this.endpoint}/${payload.modelId}`
      }

      //  fetch only when getting modelId for detail view
      //  or when is preFetching needed for detail view
      if (shouldFetch) {
        let fetchQuery = {
          ...this.fetchQuery,
          populate: populate && JSON.stringify(populate),
        }

        fetchQuery = yield buildFetchQuery(fetchQuery)
        response = yield call(
          [this.api, 'get'],
          trimDoubleSlash(url),
          fetchQuery
        )
      } else {
        //  otherwise bypass fetch and send empty response to successPayload
        response = {
          data: [],
          meta: {
            nMatched: 0,
          },
        }
      }

      let successPayload = {
        response,
        collectionName: this.modelNames.plural,
      }
      if (action.payload) {
        // pass on the request id if there was one
        successPayload.requestId = action.payload.requestId
      }
      yield put(this.fetchSuccessAction(successPayload))
      yield next(action)
    } catch (error) {
      yield put(
        adminApiCallFailed(
          new AdminError(`Fetch ${this.modelNames.plural}`, error)
        )
      )
    }
  }

  *create(action) {
    let model = action.payload.model
    try {
      yield put(adminPushIsBusy())
      yield call(
        [this.api, 'post'],
        trimDoubleSlash(`/${this.endpoint}/`),
        model
      )
      yield put(adminPopIsBusy())
      yield next(action)
    } catch (error) {
      yield put(adminPopIsBusy())
      yield put(
        adminApiCallFailed(
          new AdminError(`Create ${this.modelNames.singular}`, error)
        )
      )
    }
  }

  *update(action, overrides = {}) {
    let { method: overridenMethod, endpoint: overridenEndpoint } = overrides
    let { modelId, model, onSuccess, onError } = action.payload
    let method = overridenMethod || this.updateMethod
    let endpoint = overridenEndpoint
      ? `/${overridenEndpoint}`
      : `/${this.endpoint}/${modelId}`

    try {
      yield put(adminPushIsBusy())
      yield call([this.api, method], trimDoubleSlash(endpoint), model)
      yield put(adminPopIsBusy())
      yield next(action)
      onSuccess && onSuccess()
    } catch (error) {
      yield put(adminPopIsBusy())
      yield put(
        adminApiCallFailed(
          new AdminError(`Updating ${this.modelNames.singular}`, error)
        )
      )
      onError && onError()
    }
  }

  *delete(action, overrides = {}) {
    let { method: overridenMethod, endpoint: overridenEndpoint } = overrides
    let { modelId, onActionCompleted } = action.payload
    let method = overridenMethod || 'del'
    let endpoint = overridenEndpoint
      ? `/${overridenEndpoint}`
      : `/${this.endpoint}/${modelId}`

    try {
      yield put(adminPushIsBusy())
      yield call([this.api, method], trimDoubleSlash(endpoint))
      onActionCompleted && onActionCompleted()
      yield put(adminPopIsBusy())
      yield next(action)
    } catch (error) {
      yield put(adminPopIsBusy())
      yield put(
        adminApiCallFailed(
          new AdminError(`Delete ${this.modelNames.singular}`, error)
        )
      )
    }
  }

  *hardDelete(action, overrides) {
    let { method: overriddenMethod, endpoint: overriddenEndpoint } = overrides
    let { modelId } = action.payload
    let method = overriddenMethod || 'del'
    let endpoint = overriddenEndpoint || `/${this.endpoint}/${modelId}`

    try {
      yield call([this.api, method], trimDoubleSlash(endpoint))
      yield next(action)
    } catch (error) {
      yield put(
        adminApiCallFailed(
          new AdminError(`Delete ${this.modelNames.singular}`, error)
        )
      )
    }
  }

  *routeTrigger(args) {
    const request = yield select((state) => {
      return state.admin.request
    })
    const shouldPrefetch = this.getShouldPrefetch(request)

    if (shouldPrefetch) {
      yield all(
        this.preFetchActions.map((action) => {
          return put(action({ isPreFetch: true }))
        })
      )
    }

    if (!request.isNew) {
      const { assoc, modelId, requestId } = request
      yield put(this.fetchAction({ assoc, modelId, requestId }))
    }
    if (!args || !args.preservePagination) {
      yield put(adminSetRequestMeta({ skip: 0 }))
    }
    yield put(adminSetBreadcrumbs(this.getRouteBreadcrumbs(request)))
    yield put(adminSetRefreshFn(this.refresh.bind(this)))
  }

  *refresh() {
    yield this.routeTrigger({ preservePagination: true })
  }

  /**
   * By default we run prefetches only when showing a single object
   * (XXXForm), not when showing the list view (XXXTable). Subclasses
   * can override if needed.
   */
  getShouldPrefetch(request) {
    return request.isSingle && this.preFetchActions
  }

  /**
   * By default, we fetch when the action is specifying a specific model,
   * or when we are prefetching. Subclasses can override if needed.
   */
  getShouldFetch(action) {
    return (
      action.payload && (action.payload.modelId || action.payload.isPreFetch)
    )
  }

  // kinda lame, subclasses override if needed.
  getRouteBreadcrumbs(request) {
    const url = request.names.plural.split(' ').join('-').toLowerCase()
    if (request.isSingle) {
      const editWord = request.isNew ? 'New' : 'Edit'
      return [
        new Breadcrumb(capitalize(request.names.plural), `${url}/`),
        new Breadcrumb(
          `${editWord} ${capitalize(request.names.singular)}`,
          `${url}/${request.modelId}`
        ),
      ]
    } else {
      return [new Breadcrumb(capitalize(request.names.plural), `${url}/`)]
    }
  }
}

/**
 * Most admin stuff comes in pairs: an XXXTable and an XXXForm.
 * For the exceptions where we have only a single page UI, this
 * superclass can hold common logic. By default the (kind of
 * ridiculously complicated) fetch logic won't fetch when a specific
 * model id isn't supplied. These single-page situations won't have a
 * model id, so we should always allow fetches to run.
 */
export class NoTableAdminCrudSagas extends AdminCrudSagas {
  getShouldPrefetch() {
    return true
  }

  getShouldFetch(action) {
    return true
  }
}
